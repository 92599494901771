import React, { ReactElement, useRef } from 'react';

import styles from './TakePhoto.module.scss';
import useTranslation from '../../../hooks/useTranslation';
import useTakePhoto from './hooks/useTakePhoto';

import Button from '../../_ui/Button/Button';

interface props {
    onPhotoTaken: (src: string) => void
    handleClose: () => void
}

/**
 * Panel that shows the feed from the users device camera.
 * @returns {ReactElement}
 */
const TakePhoto = ({ onPhotoTaken, handleClose }: props): ReactElement => {
    // Gets the translate function.
    const { translate } = useTranslation();

    // Creates a ref for the video element.
    const videoRef = useRef<HTMLVideoElement>(null);

    // Gets the handle take photo function.
    const { handleTakePhoto } = useTakePhoto(videoRef, onPhotoTaken);

    // Function to close the panel and take a screenshot of the video stream.
    const handleTakePhotoClick = () => {
        handleTakePhoto();
        handleClose();
    };

    return (
        <aside className={styles.wrapper}>
            <div className="m-auto">
                <video ref={videoRef} className={styles.video}>
                    <track kind="captions" />
                </video>
                <div className=" container-xsmall mt-6">
                    <Button type="button" onClick={handleTakePhotoClick} className={styles.photoButton}>
                        {translate('photo_instructions.buttons.take_photo')}
                    </Button>
                </div>
            </div>
        </aside>
    );
};

export default TakePhoto;
