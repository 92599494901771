/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

import useTranslation from '../../../hooks/useTranslation';
import styles from './TermsAndConditions.module.scss';

import PanelNavigation from '../../_ui/PanelNavigation/PanelNavigation';
import DesktopHeader from '../../_ui/DesktopHeader/DesktopHeader';

interface props {
    onClose: () => void
}

/**
 * The terms and conditions panel to overlay a page.
 */
const TermsAndConditions = ({ onClose }: props): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();

    return (
        <aside className={styles.wrapper}>
            <DesktopHeader onClose={onClose} />
            <div className="p-7.5 my-auto overflow-y-auto h-full">
                <div className="container-large">
                    <PanelNavigation onClose={onClose}>
                        <h2 className="text-heading">{translate('terms_and_conditions.title')}</h2>
                    </PanelNavigation>
                    <div
                        className="mt-7 wysiwyg"
                        dangerouslySetInnerHTML={{ __html: translate('terms_and_conditions.body_text') }}
                    />
                </div>
            </div>
        </aside>
    );
};

export default TermsAndConditions;
