import netlifyConfig from './config/netlify_config';
import getNetlifyUrl from './helpers/getNetlifyUrl';

export const createContact = async (body: string | FormData): Promise<Response> => {
    const url = getNetlifyUrl();

    const config = {
        ...netlifyConfig,
        method: 'POST',
        body
    };

    if (process.env.REACT_APP_USE_API_MOCK === 'true') {
        config.headers['x-mock-response-name'] = 'Hubspot - 201';
    }

    return fetch(url, config);
};
