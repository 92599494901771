/**
 * Rounds a number to the nearest decimol.
 * @param number - The number to round.
 * @returns {number}
 */
export const roundToNearestDecimol = (number: number) => Math.round(number * 10) / 10;

/**
 * Calculates the number out of 10.
 * @param number - The value.
 * @param outOf - the max the value can be out of.
 * @returns {number}
 */
export const calculatePercentage = (number: number, outOf: number): number => ((number / outOf) * 100);

/**
 * Scales a number betwen two numbers and then scales that out to between the new min and max.
 * @param num - The number to scale.
 * @param inMin - The min the number can be.
 * @param inMax - The max the number can be.
 * @param outMin - Scale the number between the new min.
 * @param outMax - Scale the number between the new max.
 * @returns {number}
 */
export const scaleNumber = (num: number, inMin: number, inMax: number, outMin: number, outMax: number): number => {
    return ((num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin); // eslint-disable-line
};

/**
 * Rounds a number to the nearest number provided.
 * @param number - The number to round.
 * @param round - The nearest number to round 10.
 * @returns {number}
 */
export const roundToTheNearest = (number: number, round: number): number => Math.round(number / round) * round;
