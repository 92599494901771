import lifestyles from '../../config/lifestyles.json';
import { removeArrayDuplicates, removeArrayItem } from '../modifyArray';
import { roundToNearestDecimol, calculatePercentage } from '../maths/maths';


type getDefaultChoiceInterface = lifestyleChoiceInterface | undefined;

type calculateAgeModifierType = 20 | 10 | 5 | 0;

interface calculateDefaultScoresInterface {
    currentScore: number,
    potentialScore: number,
    effectIds: (number|string)[],
    iterationCounts: number[],
    ageModifier: number
}

/**
 * Gets the default choice from a question.
 * @param question - The question containing the choices.
 * @returns {lifestyleChoiceInterface | undefined}
 */
export const getDefaultChoice = (question: lifestyleQuestionInterface): getDefaultChoiceInterface => {
    const { default_choice: defaultChoice, choices } = question;

    return choices.find(({ id }) => id === defaultChoice);
};

/**
 * If the users score is within a bracket, return the correct age modifier.
 * @param score - The users questionnaire score.
 * @returns {number}
 */
export const calculateAgeModifier = (score: number): calculateAgeModifierType => {
    switch (true) {
    case score === 0:
        return 20;
    case score <= 30:
        return 10;
    case score <= 60:
        return 5;
    default:
        return 0;
    }
};

// TODO: Below function needs case testing. Currently, it only has a generic types test.
/**
 * Calculates the users lifestyle scores, age modifier and any effect IDs to add to the user.
 * @param givenAnswers - The answers of the questionnaire.
 * @returns {calculateDefaultScoresInterface}
 */
const calculateScores = (givenAnswers: lifestyleAnswersInterface, ageingOnly = false): calculateDefaultScoresInterface => {
    const hasAnswers = Object.keys(givenAnswers).length > 0;

    // If the user is ageing themselves only.
    if (ageingOnly || hasAnswers === false) {
        return {
            currentScore: 0,
            potentialScore: 0,
            effectIds: [154],
            iterationCounts: [1],
            ageModifier: 0
        };
    }

    // The max score of the questionnaire.
    let maxOverallScore = 0;

    // The current users score.
    let currentScore = 0;

    // The users potential score if they improve their life.
    let potentialScore = 0;

    // Any effect IDs to be applied to the image the user uploaded.
    let effectIds: (number|string)[] = [];

    const excludeIds: (number|string)[] = [];

    // An array of iteration counts, one for each supplied effect ID
    const iterationCounts: number[] = [];

    const { questions } = lifestyles;

    questions.forEach((question) => {
        const { maximum_score: maxScore, id } = question;
        const choice = givenAnswers[id];

        // If the choice does not exist, ignore this choice.
        if (typeof choice === 'undefined') {
            return;
        }

        const { score, excluded_ids, effect_ids: choiceEffectIds, iteration_count: iterationCount } = choice;

        maxOverallScore += maxScore;
        currentScore += score;
        potentialScore += score < maxScore ? (score + 1) : score;

        // If there is no effect ID, don't add one.
        if (choiceEffectIds.length) {
            choiceEffectIds.forEach((effect) => {
                if (effectIds.includes(effect)) {
                    return;
                }
                effectIds.push(effect);
                iterationCounts.push(iterationCount);
            });
        }

        if (excluded_ids.length) {
            excluded_ids.forEach((effect) => {
                if (excludeIds.includes(effect)) {
                    return;
                }
                excludeIds.push(effect);
            });
        }
    });

    excludeIds.forEach((excludeId) => {
        if (effectIds.includes(excludeId)) {
            effectIds = removeArrayItem(effectIds, (effect) => {
                // TODO: Find a better way to keep iterationCount and effectIds in sync
                if (effect === excludeId) {
                    iterationCounts.pop();
                    return true;
                }
                return false;
            });
        }
    });

    currentScore = roundToNearestDecimol(calculatePercentage(currentScore, maxOverallScore));

    return {
        currentScore,
        potentialScore: roundToNearestDecimol(calculatePercentage(potentialScore, maxOverallScore)),
        effectIds: removeArrayDuplicates(effectIds),
        iterationCounts,
        ageModifier: calculateAgeModifier(currentScore)
    };
};

export default calculateScores;
