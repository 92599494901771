interface getRatingColourInterface {
    textColour: 'text-white' | 'text-dark',
    bgColour: 'bg-improved' | 'bg-success' | 'bg-warning' | 'bg-error'
}

/**
 * Gets the rating colour based on the value given.
 * @param value - The number to get the colour value for.
 * @returns {getRatingColourInterface}
 */
const getRatingColour = (value: number): getRatingColourInterface => {
    switch (true) {
    case value < 20:
        return {
            textColour: 'text-dark',
            bgColour: 'bg-error'
        };
    case value < 40:
        return {
            textColour: 'text-white',
            bgColour: 'bg-error'
        };
    case value < 70:
        return {
            textColour: 'text-dark',
            bgColour: 'bg-warning'
        };
    case value < 100:
        return {
            textColour: 'text-white',
            bgColour: 'bg-success'
        };
    case value === 100:
        return {
            textColour: 'text-dark',
            bgColour: 'bg-improved'
        };
    default:
        return {
            textColour: 'text-dark',
            bgColour: 'bg-warning'
        };
    }
};

export default getRatingColour;
