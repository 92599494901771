import React, { ReactElement } from 'react';
import useTranslation from '../../../hooks/useTranslation';
import PanelNavigation from '../../_ui/PanelNavigation/PanelNavigation';
import styles from './LifestyleChoiceDetails.module.scss';

interface props {
    lifestyleChoice: lifestyleChoiceInterface,
    showGoodFuture?: boolean,
    onClose: () => void
}

/**
 * Panel displaying all the information about a given lifestyle choice.
 * @returns {ReactElement}
 */
const LifestyleChoiceDetails = ({ lifestyleChoice, showGoodFuture, onClose }: props): ReactElement | null => {
    const { translate } = useTranslation();

    if (lifestyleChoice === null) {
        return null;
    }

    // Get the details of the choice.
    const {
        title,
        description,
        icon,
        potential: {
            icon: potentialIcon,
            title: potentialTitle,
            description: potentialDescription
        }
    } = lifestyleChoice;

    // Select the details based on whether or not to show the base information or the potential.
    const readMoreTitle = translate(showGoodFuture ? potentialTitle : title);
    const readMoreDescription = translate(showGoodFuture ? potentialDescription : description);
    const src = showGoodFuture ? potentialIcon : icon;

    return (
        <aside className={styles.wrapper}>
            <div className={`container-large ${styles.inner}`}>
                <PanelNavigation onClose={onClose}>
                    <img src={src} alt={readMoreTitle} className="-ml-3" />
                </PanelNavigation>
                <h3 className="text-subtitle mt-8">{readMoreTitle}</h3>
                <p className="mt-6">{readMoreDescription}</p>
            </div>
        </aside>
    );
};

LifestyleChoiceDetails.defaultProps = {
    showGoodFuture: false
};

export default LifestyleChoiceDetails;
