import React, { ReactElement } from 'react';

import pageStyles from '../scss/Pages.module.scss';
import usePageContext from '../../context/pageContext';
import styles from './Success.module.scss';

import PageNavigation from '../../components/_ui/PageNavigation/PageNavigation';
import useTranslation from '../../hooks/useTranslation';
import Button from '../../components/_ui/Button/Button';
import useGlobalContext from '../../context/globalContext';

/**
 * The success page.
 * @returns {ReactElement}
 */
const Success = (): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();

    // Get the page navigation function.
    const { jumpToPage } = usePageContext();

    // Get the set user data function.
    const { clearData } = useGlobalContext();

    /**
     * Clears the users data and jumps to the start of the app.
     */
    const handleRestart = () => {
        clearData();
        jumpToPage('START');
    };

    return (
        <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex`}>
            <section className={`container-xsmall ${styles.inner}`}>
                <div className="flex-grow-0 flex-shrink-0">
                    <PageNavigation />
                </div>
                <div className="flex-grow mt-6">
                    <h1 className="text-subtitle">{translate('success.title')}</h1>
                    <p className="mt-2">{translate('success.body_text')}</p>
                </div>
                <div className={styles.button}>
                    <Button className="w-full" primary={false} onClick={handleRestart}>
                        {translate('success.buttons.restart')}
                    </Button>
                </div>
            </section>
        </main>
    );
};

export default Success;
