interface getWindowSizeInterface {
    windowWidth: number,
    windowHeight: number
}

const getWindowSize = (): getWindowSizeInterface => {
    if (typeof window === 'undefined') {
        return {
            windowWidth: 0,
            windowHeight: 0
        };
    }

    return {
        windowWidth: window.innerWidth,
        windowHeight: window.innerWidth
    };
};

export default getWindowSize;
