import React, { ReactElement, memo, Fragment } from 'react';

import useGlobalContext from '../../../context/globalContext';
import useTranslation from '../../../hooks/useTranslation';
import styles from './GoodFuture.module.scss';

import Rating from '../../_ui/Rating/Rating';
import LifestyleReadMore from '../../_ui/LifestyleReadMore/LifestyleReadMore';

interface props {
    onReadMore: (choice: lifestyleChoiceInterface) => void
}

/**
 * Shows the good / potential lifestyle score a user could have.
 * @returns {ReactElement}
 */
const GoodFuture = ({ onReadMore }: props): ReactElement | null => {
    // Get the translation function.
    const { translate } = useTranslation();

    // Get the lifestyle scores and the users lifestyle answers.
    const { lifestyleScores, lifestyleAnswers } = useGlobalContext();

    // Get the current and potential lifestyle score.
    const potentialScore = lifestyleScores ? lifestyleScores.potentialScore : 0;
    const currentScore = lifestyleScores ? lifestyleScores.currentScore : 0;

    // Store the score grade to get the relevant score description.
    let scoreDescription = '';

    // Get the score grade.
    switch (true) {
    case currentScore === 100:
        scoreDescription = 'top';
        break;
    case currentScore >= 70:
        scoreDescription = 'good';
        break;
    case currentScore >= 40:
        scoreDescription = 'average';
        break;
    case currentScore < 40:
    default:
        scoreDescription = 'poor';
        break;
    }

    // If there are no answers from the questionnaire.
    if (Object.keys(lifestyleAnswers).length === 0) {
        return null;
    }

    return (
        <Fragment>
            <h2 className="text-heading text-red">
                {translate('compare_your_face.scores.good_future.title')}
            </h2>
            <p className="text-subheading mt-2">
                {translate(`compare_your_face.scores.good_future.${scoreDescription}`)}
            </p>
            <div className="flex align-center mt-4">
                <span className={`${styles.scoreLabel} text-label`}>You: </span>
                <Rating value={currentScore} className="flex-grow" />
            </div>
            <div className="flex align-center mt-3">
                <span className={`${styles.scoreLabel} text-label`}>Potential:</span>
                <Rating value={potentialScore} className="flex-grow" />
            </div>
            <section className="mt-8">
                <h2 className="text-heading">{translate('compare_your_face.scores.bad_future.subtitle')}</h2>
                <ul className="mt-4">
                    {Object.keys(lifestyleAnswers).map((questionId) => {
                        const choice = lifestyleAnswers[questionId];

                        return (
                            <li key={questionId} className="mt-4">
                                <LifestyleReadMore
                                    lifestyleChoice={choice}
                                    showGoodFuture
                                    onReadMore={onReadMore}
                                />
                            </li>
                        );
                    })}
                </ul>
            </section>
        </Fragment>
    );
};

// Component will only render once as it is wrapped in memo.
export default memo(GoodFuture);
