import React, { ReactElement, Fragment } from 'react';

import pageStyles from '../../scss/Pages.module.scss';
import styles from './CompareYourFaceLayout.module.scss';

import DelayOverlay from '../../../components/_ui/Delay/Delay';
import ImageComparison from '../ImageComparison/ImageComparison';
import CompareYourFacePanels from '../CompareYourFacePanels/CompareYourFacePanels';
import useCompareYourFaceContext from '../context/compareYourFaceContext';

/**
 * The compare your face page.
 * @returns {ReactElement}
 */
const CompareYourFaceLayout = (): ReactElement => {
    const { showScores, DescriptionToRender, showDelayOverlay } = useCompareYourFaceContext();

    return (
        <Fragment>
            <main className={`${pageStyles.page} overflow-auto md:p-8 _js-compare-you-face-wrapper`}>
                <div className={`${styles.inner} container-xxlarge`}>
                    <ImageComparison />
                    <section className={`${styles.scoreCard} ${showScores ? styles.scoreCardShow : ''} _js-score`}>
                        <DescriptionToRender />
                    </section>
                </div>
                <CompareYourFacePanels />
            </main>
            <DelayOverlay show={showDelayOverlay} message="delay.good_future" />
        </Fragment>
    );
};

export default CompareYourFaceLayout;
