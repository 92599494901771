import { useEffect, useReducer } from 'react';

import isJsonResponse from '../helpers/isJsonResponse';

export interface reducerState {
    isLoading: boolean,
    error: boolean,
    success: boolean,
    errorResponseJson?: any,
    data: any
}

interface reducerAction {
    type: 'LOADING' | 'SUCCESS' | 'ERROR' | 'RESET',
    data: any,
    errorResponseJson?: any,
}

/**
     * Updates the reducer state.
     * @param {object} state - Current state of the reducer.
     * @param {object} action - Contains the type for the reducer.
     * @returns {object}
     */
const reducer = (state: reducerState, action: reducerAction): reducerState => {
    const { type, data } = action;

    switch (type) {
    case 'LOADING':
        return {
            isLoading: true,
            error: false,
            success: false,
            data: null
        };
    case 'SUCCESS':
        return {
            isLoading: false,
            error: false,
            success: true,
            data
        };
    case 'ERROR':
        return {
            isLoading: false,
            error: true,
            success: false,
            errorResponseJson: action.errorResponseJson,
            data
        };
    case 'RESET':
    default:
        console.error('No state provided.');
        return {
            isLoading: false,
            error: false,
            success: false,
            errorResponseJson: null,
            data: null
        };
    }
};

class FetchError extends Error {
    responseJson: any
}

const useFetch = (request: (...a: any[]) => any, runOnInit = false, delay = 0) => {
    // What state the reducer should start with.
    const initialState: reducerState = {
        isLoading: runOnInit,
        error: false,
        success: !runOnInit,
        data: null
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    /**
     * Send the fetch request passed in via callback.
     */
    const sendRequest = async (...args: any[]) => {
        dispatch({
            type: 'LOADING',
            data: null
        });

        // Sets an API call delay if there is one.
        setTimeout(async () => {
            await request(...args)
                .then(async (response: any) => {
                    if (!response.ok) {
                        const error = new FetchError(response);
                        error.responseJson = await response.json();
                        throw error;
                    }

                    if (isJsonResponse(response) === false) {
                        dispatch({ type: 'SUCCESS', data: response });
                        return response;
                    }

                    const responseJson = await response.json();

                    if (!responseJson || (typeof responseJson.success !== 'undefined' && responseJson.success === false)) {
                        throw response;
                    }

                    dispatch({ type: 'SUCCESS', data: responseJson });
                    return responseJson;
                })
                .catch((error: any) => {
                    console.error('There has been a problem with your fetch operation:', error);
                    dispatch({ type: 'ERROR', data: error, errorResponseJson: error.responseJson });
                });
        }, delay);
    };

    /**
     * If runOnInit is true, run the fetch request.
     */
    useEffect(() => {
        if (runOnInit) {
            sendRequest();
        }
    }, []);

    return {
        state,
        sendRequest,

        dispatch
    };
};

export default useFetch;
