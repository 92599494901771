import { useState } from 'react';

const RELEASED_DELAY = 1000;

interface useOnReleaseInterface {
    onRelease?: () => void;
}

const useOnRelease = (callback: (() => void) | null = null): useOnReleaseInterface => {
    // Stores the if the release call has been made.
    const [hasReleased, setHasReleased] = useState<boolean>(callback ? false : true); // eslint-disable-line no-unneeded-ternary

    // Handles the release call.
    const onRelease = () => {
        // If the release has already happened, return.
        if (hasReleased === true || callback === null) {
            return;
        }

        // Call the callback in a timeout delay.
        setTimeout(() => {
            callback();
        }, RELEASED_DELAY);

        // Set the has released to true.
        setHasReleased(true);
    };

    return {
        onRelease: hasReleased === false ? onRelease : undefined
    };
};

export default useOnRelease;
