/**
 * Scrolls to the top of a given element.
 * @param selector - The selector of the element to scroll to.
 * @returns {void}
 */
const scrollTo = (selector: string, top = 0): void => {
    // If there is no document available, return.
    if (typeof document === 'undefined') {
        return;
    }

    // Get the lement.
    const element = document.querySelector(selector);

    // If there is no element, return.
    if (element === null) {
        return;
    }

    // Scroll to the element.
    element.scrollTo({ top, behavior: 'smooth' });
};

export default scrollTo;
