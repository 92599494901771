import React, { ReactElement } from 'react';

const Check = (): ReactElement => (
    <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.0835 56.3508H2.92234C1.87828 56.3508 0.913533 55.7938 0.391511 54.8896C-0.13051 53.9854 -0.130503 52.8714 0.39153 51.9672L27.4706 5.20855C27.9931 4.30585 28.957 3.75 30 3.75C31.043 3.75 32.0069 4.30585 32.5294 5.20855L59.6085 51.9672C60.1302 52.871 60.1305 53.9843 59.6092 54.8883C59.0879 55.7923 58.1241 56.3498 57.0806 56.3508H57.0835ZM30.0044 12.5146L7.99566 50.506H52.019L30.0044 12.5146ZM32.9122 38.8193H27.0674V24.2043H32.9122V38.8193Z" />
        <path d="M27.082 41.7446H32.9268V47.5894H27.082V41.7446Z" />
    </svg>
);

export default Check;
