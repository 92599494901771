import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './config/i18n_config';
import { ANIMATION_SLOW } from './config/animation_config';
import { GlobalProvider } from './context/globalContext';
import { PageProvider } from './context/pageContext';
import { DesktopNavigationProvider } from './context/desktopNavigationContext';
import styles from './index.module.scss';
import './scss/styles.scss';
import reportWebVitals from './reportWebVitals';

import App from './app/App';

ReactDOM.render(
    <React.StrictMode>
        <CSSTransition
            in
            appear
            timeout={ANIMATION_SLOW}
            classNames={{
                appear: styles.a_fadeIn,
                appearActive: styles.a_fadeInActive
            }}
        >
            <GlobalProvider>
                <DesktopNavigationProvider>
                    <PageProvider>
                        <App />
                    </PageProvider>
                </DesktopNavigationProvider>
            </GlobalProvider>
        </CSSTransition>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
