import React, { ReactElement } from 'react';

import useTranslation from '../../../hooks/useTranslation';
// import { url } from '../../../config/client_config.json';
import styles from './Share.module.scss';

import PanelNavigation from '../../_ui/PanelNavigation/PanelNavigation';
import useDownloadImage from './hooks/useDownloadImage';

interface props {
    onClose: () => void
}

/**
 * The share to overlay a page.
 */
const Share = ({ onClose }: props): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();
    const downloadCurrentSelectedImage = useDownloadImage();

    const siteUrl = process.env.REACT_APP_SHARE_URL as string;

    // Generate the social links for the below pages.
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(siteUrl)}`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURI(siteUrl)}&text=${encodeURI(translate('share.links.twitter.share_description'))}`;

    // Sets the target and rel for the links.
    const rel = 'noopener noreferrer nofollow';
    const target = '_blank';

    // Text and alt text for the social button links.
    const downloadTitle = translate('share.links.download.text');
    const facebookTitle = translate('share.links.facebook.text');
    const twitterTitle = translate('share.links.twitter.text');

    return (
        <aside className={styles.aside}>
            <div className={`container-xsmall ${styles.inner}`}>
                <div>
                    <PanelNavigation onClose={onClose} />
                    <h2 className={`text-subtitle ${styles.heading}`}>{translate('share.title')}</h2>
                </div>
                <div className={styles.shareLinksWrapper}>
                    <button type="button" className={styles.download} onClick={downloadCurrentSelectedImage}>
                        <div className="w-6">
                            <img className="mx-auto" src="/icons/social/download.svg" alt={downloadTitle} />
                        </div>
                        <span className="ml-4">{downloadTitle}</span>
                    </button>
                    <a className={styles.facebook} href={facebookUrl} rel={rel} target={target}>
                        <div className="w-6">
                            <img className="mx-auto" src="/icons/social/facebook.svg" alt={facebookTitle} />
                        </div>
                        <span className="ml-4">{facebookTitle}</span>
                    </a>
                    <a className={styles.twitter} href={twitterUrl} rel={rel} target={target}>
                        <div className="w-6">
                            <img className="mx-auto" src="/icons/social/twitter.svg" alt={twitterTitle} />
                        </div>
                        <span className="ml-4">{twitterTitle}</span>
                    </a>
                </div>
            </div>
        </aside>
    );
};

export default Share;
