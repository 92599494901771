import React, { Fragment, ReactElement, useEffect } from 'react';

import useTranslation from '../../../../hooks/useTranslation';
import styles from './LifestyleSelector.module.scss';
import lifestyles from '../../../../config/lifestyles.json';
import useGlobalContext from '../../../../context/globalContext';

import SelectableCard from '../../../_ui/SelectableCard/SelectableCard';
import { removeArrayItemByIndex } from '../../../../helpers/modifyArray';

/**
 * The lifestyle questions selector and questionnaire page.
 * @returns {ReactElement}
 */
const LifestyleSelector = (): ReactElement => {
    // Get the questions from the lifestyles config.
    const { questions } = lifestyles as lifestyleQuestionListInterface;

    // Gets the translation function.
    const { translate } = useTranslation();

    // Gets the lifestyle questions state and update function from the global context.
    const { lifestyleQuestions, setLifestyleQuestions, setLifestyleAnswers } = useGlobalContext();

    /**
     * Toggles a lifestyle.
     * @param question - The lifestyle to toggle.
     * @returns {void}
     */
    const toggleLifestyle = (lifestyle: lifestyleQuestionInterface) => {
        const arrayIndex = lifestyleQuestions.findIndex(({ id }) => id === lifestyle.id);

        // If the lifestyle is already in the array, remove it.
        if (arrayIndex > -1) {
            setLifestyleQuestions(removeArrayItemByIndex(lifestyleQuestions, arrayIndex));
            return;
        }

        // Add the lifestyle to the selected lifestyles.
        setLifestyleQuestions([...lifestyleQuestions, lifestyle]);
    };

    useEffect(() => {
        setLifestyleAnswers({});
    }, []);

    const activeLifestyles = questions.filter((question) => {
        return question.category === 'default';
    });

    const futureLifestyles = questions.filter((question) => {
        return question.category === 'coming_soon';
    });

    const paidLifestyles = questions.filter((question) => {
        return question.category === 'paid';
    });

    return (
        <div className={styles.wrapper}>
            <p className="text-subheading container-small">{translate('lifestyle_questions.body_text')}</p>
            <div className={`${styles.lifestyleSelector} flow--large`}>
                <ul className={styles.questionGrid}>
                    {activeLifestyles.map((question) => {
                        // The title of the question.
                        const questionTitle = translate(question.title);

                        // On click, toggle the questions selected state.
                        const handleClick = () => toggleLifestyle(question);

                        // Is the question selected.
                        const checked = lifestyleQuestions.findIndex(({ id }) => id === question.id) > -1;

                        return (
                            <li key={question.id} className={styles.questionCard}>
                                <SelectableCard onClick={handleClick} checked={checked} className="pt-2.5 pb-4">
                                    <img src={question.icon} alt={questionTitle} className={styles.image} />
                                    <h3 className={`text-label ${styles.questionLabel}`}>{questionTitle}</h3>
                                </SelectableCard>
                            </li>
                        );
                    })}
                </ul>
                {
                    (paidLifestyles.length)
                        ? (
                            <Fragment>
                                <div className="sep-heading">
                                    <span className="text-label">Available on our paid plan</span>
                                    <hr />
                                </div>

                                <ul className={styles.questionGrid}>
                                    {paidLifestyles.map((question) => {
                                        // The title of the question.
                                        const questionTitle = translate(question.title);

                                        // On click, toggle the questions selected state.
                                        const handleClick = () => toggleLifestyle(question);

                                        // Is the question selected.
                                        const checked = lifestyleQuestions.findIndex(({ id }) => id === question.id) > -1;

                                        return (
                                            <li key={question.id} className={styles.questionCard}>
                                                <SelectableCard onClick={handleClick} checked={checked} className="pt-2.5 pb-4" disabled>
                                                    <img src={question.icon} alt={questionTitle} className={styles.image} />
                                                    <h3 className={`text-label ${styles.questionLabel}`}>{questionTitle}</h3>
                                                </SelectableCard>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        )
                        : null
                }
                {
                    (futureLifestyles.length)
                        ? (
                            <Fragment>
                                <div className="sep-heading">
                                    <span className="text-label">Coming soon</span>
                                    <hr />
                                </div>

                                <ul className={styles.questionGrid}>
                                    {futureLifestyles.map((question) => {
                                        // The title of the question.
                                        const questionTitle = translate(question.title);

                                        // On click, toggle the questions selected state.
                                        const handleClick = () => toggleLifestyle(question);

                                        // Is the question selected.
                                        const checked = lifestyleQuestions.findIndex(({ id }) => id === question.id) > -1;

                                        return (
                                            <li key={question.id} className={styles.questionCard}>
                                                <SelectableCard onClick={handleClick} checked={checked} className="pt-2.5 pb-4" disabled>
                                                    <img src={question.icon} alt={questionTitle} className={styles.image} />
                                                    <h3 className={`text-label ${styles.questionLabel}`}>{questionTitle}</h3>
                                                </SelectableCard>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        )
                        : null
                }

            </div>
        </div>
    );
};

export default LifestyleSelector;
