import React, { ReactElement, useState } from 'react';

import { name, logo_large } from '../../config/client_config.json';
import useGlobalContext, { USER_ALLOWANCE_LIMIT } from '../../context/globalContext';
import usePageContext from '../../context/pageContext';
import pageStyles from '../scss/Pages.module.scss';
import useTranslation from '../../hooks/useTranslation';
import styles from './Splash.module.scss';

import TermsAndConditions from '../../components/Panels/TermsAndConditions/TermsAndConditions';
import PanelAnimation from '../../components/Panels/PanelAnimation/PanelAnimation';
import Button from '../../components/_ui/Button/Button';
import Checkbox from '../../components/_ui/Forms/Inputs/Checkbox/Checkbox';
import TranslationSwitcher from '../../components/TranslationSwitcher/TranslationSwitcher';

/**
 * Splash page that is shown to the user on load. This page is automatically redirected to the next page.
 */
const Splash = (): ReactElement => {
    // Set the show/hide state for terms and conditions.
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [TermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
    const toggleTermsChecked = () => setTermsAndConditionsChecked((state) => !state);

    const { userAllowance } = useGlobalContext();

    // Get page navigation functions.
    const { onNextPage, jumpToPage } = usePageContext();

    // Get the translate function
    const { translate } = useTranslation();

    /**
     * Toggles the display of the terms and conditions.
     */
    const toggleTermsAndConditions = () => {
        setShowTermsAndConditions((state) => !state);
    };

    return (
        <main data-testid="splash" className={`${pageStyles.page} ${styles.wrapper}`}>
            <div className={styles.header}>
                <div className={styles.roundBackground} />
                <img className={styles.splashLogo} src={logo_large} alt={name} />
            </div>

            {
                (userAllowance() < USER_ALLOWANCE_LIMIT)
                    ? (
                        <div className={styles.content}>
                            <div className="container-xsmall">
                                <label className={`${styles.label} text-note`} htmlFor="terms-checkbox">
                                    <span>
                                        {`${translate('splash.body_text')} `}
                                        <button
                                            className="text-link text-primary inline"
                                            type="button"
                                            onClick={toggleTermsAndConditions}
                                        >
                                            {translate('splash.buttons.terms_and_conditions')}
                                        </button>
                                    </span>
                                    <span className="inline-block">
                                        <Checkbox
                                            id="terms-checkbox"
                                            checked={TermsAndConditionsChecked}
                                            onChange={toggleTermsChecked}
                                        />
                                    </span>
                                </label>
                                <Button
                                    className="text-white w-full"
                                    type="button"
                                    onClick={onNextPage}
                                    disabled={TermsAndConditionsChecked === false}
                                >
                                    {translate('splash.buttons.get_started')}
                                </Button>
                            </div>
                        </div>
                    )
                    : (
                        <div className={styles.contentBlocked}>
                            <div className="container-xsmall flow">
                                <h1 className="text-heading">{translate('splash.blocked_title')}</h1>
                                <p>{translate('splash.blocked_body_text')}</p>
                                <Button
                                    className="text-white w-full"
                                    type="button"
                                    onClick={() => { jumpToPage('LEAD_CAPTURE_FORM'); }}
                                >
                                    {translate('splash.blocked_buttons.get_started')}
                                </Button>
                            </div>
                        </div>
                    )
            }
            <TranslationSwitcher />
            <PanelAnimation showPanel={showTermsAndConditions}>
                <TermsAndConditions onClose={toggleTermsAndConditions} />
            </PanelAnimation>
        </main>
    );
};

export default Splash;
