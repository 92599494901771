import React from 'react';
import styles from './Radio.module.scss';

interface RadioProps {
    id: string,
    checked?: boolean,
    value: string,
    name: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    children:React.ReactNode,
    className?:string,
}

const Radio = ({
    checked = false, id, name, value, children, onChange, onBlur, className = ''
}: RadioProps): JSX.Element => {
    return (
        <div className={`${className} ${styles.Radio}`}>
            <input type="radio" id={id} name={name} value={value} onChange={onChange} onBlur={onBlur} checked={checked} className={styles.RadioField} />
            <label htmlFor={id} className={styles.RadioLabel}>
                {children}
            </label>
        </div>
    );
};

export default Radio;
