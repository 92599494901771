import React, { ReactElement, useState, FormEvent, ChangeEventHandler, useEffect } from 'react';
import Lottie from 'react-lottie';

import swipeHand from '../../../data/animations/swipeHand.json';
import { scaleNumber } from '../../../helpers/maths/maths';
import useOnRelease from './hooks/useOnRelease';
import useHint from './hooks/useHint';
import styles from './ImageComparisonSlider.module.scss';

interface props {
    backgroundImage: string,
    foregroundImage: string,
    releaseCallback?: () => void
}

const defaultWidth = 0;

const ImageComparisonSlider = ({ backgroundImage, foregroundImage, releaseCallback }: props): ReactElement => {
    const [width, setWidth] = useState(defaultWidth);

    // Get the show hint functionality.
    const { showHint, handleHideHint, clearHintTimeout } = useHint();

    // Hook to create
    const { onRelease } = useOnRelease(releaseCallback);

    /**
     * Sets the new width of the image slider crop div.
     * @param e - The event from the range input.
     */
    const handleSlide = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.target as HTMLInputElement;
        setWidth(parseInt(value, 10));

        // Clears the show hint timeout if it's still there.
        clearHintTimeout();

        // Sets the showHint back to false if it's still visible.
        handleHideHint();
    };

    // IOS disable swipe to go back.
    const disableIosSwipe = (e: FormEvent<HTMLInputElement>) => {
        e.preventDefault();
    };

    // The width of the cropping div.
    const divWidth = scaleNumber(width, 0, 100, 10, 90);

    useEffect(() => {
        setWidth(defaultWidth);
    }, [backgroundImage, foregroundImage]);

    return (
        <div className={styles.container}>
            <div
                className={styles.image}
                style={{ backgroundImage: `url('${backgroundImage}')` }}
            />
            <div style={{ width: `${divWidth}%` }} className={styles.cropContainer}>
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url('${foregroundImage}')` }}
                />
            </div>
            <div className={styles.track}>
                <input
                    type="range"
                    min="0"
                    max="100"
                    defaultValue={width}
                    className={styles.rangeInput}
                    name="slider"
                    onTouchStart={disableIosSwipe}
                    onInput={handleSlide}
                    onMouseUp={onRelease}
                    onTouchEnd={onRelease}
                />
                <div style={{ left: `${divWidth}%` }} className={styles.scrub}>
                    <img src="/icons/chevron-left.svg" alt="Cheveron-left" />
                    <img src="/icons/chevron-left.svg" alt="Cheveron-right" />
                    {showHint ? (
                        <div className={styles.hint}>
                            <Lottie options={{ animationData: swipeHand }} width={60} height={60} />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

ImageComparisonSlider.defaultProps = {
    releaseCallback: undefined
};

export default ImageComparisonSlider;
