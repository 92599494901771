import useGlobalContext from '../../../../context/globalContext';
import useCompareYourFaceContext from '../../../../pages/CompareYourFace/context/compareYourFaceContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDownloadImage = () => {
    const { badFutureImages, goodFutureImages } = useGlobalContext();
    const { relativeAge, showGoodFuture } = useCompareYourFaceContext();

    const activeBadFutureImage = badFutureImages[relativeAge]?.output_file;
    const activeGoodFutureImage = goodFutureImages[relativeAge]?.output_file;

    const imageToShare = (showGoodFuture) ? activeGoodFutureImage : activeBadFutureImage;

    return () => {
        fetch(imageToShare)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a new URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a new anchor element
                const a = document.createElement('a');
                a.href = url;
                a.download = 'change-my-face.jpeg';

                // Append the anchor to the body, trigger click, and then remove it
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // Clean up by revoking the object URL
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                // Locally we get a cors error, so if we have this on any of the domains. I want to make sure that we can still download or present the image to the user.
                const a = document.createElement('a');
                a.href = imageToShare;
                a.download = 'change-my-face.jpeg';
                a.target = '_blank';
                a.click();
            });
    };
};

export default useDownloadImage;
