import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon/Icon';

import styles from './Dialog.module.scss';

interface DialogProps {
    title:string,
    children:React.ReactNode,
    isOpen?:boolean,
    onClose: () =>void
}

const transitionClassNames = {
    enter: styles.DialogTransitionEnter,
    enterActive: styles.DialogTransitionEnterActive,
    enterDone: styles.DialogTransitionEnterDone,
    exit: styles.DialogTransitionExit,
    exitActive: styles.DialogTransitionExitActive,
    exitDone: styles.DialogTransitionExitDone
};

const transitionOverlayClassNames = {
    enter: styles.DialogOverlayTransitionEnter,
    enterActive: styles.DialogOverlayTransitionEnterActive,
    enterDone: styles.DialogOverlayTransitionEnterDone,
    exit: styles.DialogOverlayTransitionExit,
    exitActive: styles.DialogOverlayTransitionExitActive,
    exitDone: styles.DialogOverlayTransitionExitDone
};


const Dialog = ({ title, children, isOpen, onClose }:DialogProps):JSX.Element => {
    return (
        <Fragment>
            <CSSTransition in={isOpen} mountOnEnter unmountOnExit classNames={transitionOverlayClassNames} timeout={{ enter: 500, exit: 500 }}>
                <button className={styles.DialogOverlay} onClick={onClose} type="button" />
            </CSSTransition>
            <CSSTransition in={isOpen} mountOnEnter unmountOnExit classNames={transitionClassNames} timeout={{ enter: 500, exit: 500 }}>
                <dialog className={styles.Dialog}>
                    <header className={styles.DialogHeader}>
                        <span className="text-subheading">{title}</span>
                        <button type="button" onClick={onClose}>
                            <Icon icon="CROSS" width={20} height={16} className={styles.validationIcon} />
                        </button>
                    </header>

                    <main className="space-y-2">
                        {children}
                    </main>
                </dialog>
            </CSSTransition>
        </Fragment>
    );
};

Dialog.defaultProps = {
    isOpen: false
};

export default Dialog;
