import React, { ReactElement, ReactNode } from 'react';

import styles from './FieldLabel.module.scss';

import Icon from '../../Icon/Icon';

interface props {
    id: string,
    label: string,
    className?: string,
    isValid?: boolean,
    children: ReactNode,
    errorMessage?: string
}

const FieldLabel = ({ id, label, className, isValid, errorMessage, children }: props): ReactElement => (
    <div className={className}>
        <div className={styles.wrapper}>
            <label className={`${styles.label} text-note`} htmlFor={id}>{label}</label>
            <div className="relative">
                {children}
                {isValid ? (
                    <Icon icon="CHECK" width={20} height={16} className={styles.validationIcon} />
                ) : null}
            </div>
        </div>
        {errorMessage ? <div className="mt-1.5 text-note text-error px-3.5">{errorMessage}</div> : null}
    </div>
);

FieldLabel.defaultProps = {
    className: '',
    errorMessage: undefined,
    isValid: false
};

export default FieldLabel;
