import React, { ReactElement, memo, useEffect, useState } from 'react';
import Lottie from 'react-lottie';

import useTranslation from '../../../hooks/useTranslation';
import styles from './Loading.module.scss';
import faceScan from '../../../data/animations/faceScan.json';
import searchFace from '../../../data/animations/searchFace.json';

const animations = {
    FACE_SCAN: faceScan,
    SEARCH_FACE: searchFace
};

interface props {
    lottie: keyof typeof animations
}

/**
 * The loading section as either a page or panel.
 * @returns {ReactElement}
 */
const Loading = ({ lottie }: props): ReactElement => {
    // Get the translation functions.
    const { translate, randomTranslation } = useTranslation();

    const [randomFact, setRandomFact] = useState(randomTranslation('loading.body_text'));

    useEffect(() => {
        const factInterval = setInterval(() => {
            setRandomFact(randomTranslation('loading.body_text'));
        }, 7000);

        return () => {
            clearInterval(factInterval);
        };
    }, [randomFact]);

    // Get the animation to display.
    const animationData = animations[lottie];

    return (
        <section className={styles.wrapper}>
            <div className="container-xsmall">
                <div className={styles.lottie}>
                    <Lottie options={{ animationData }} />
                </div>
                <h2 className="text-heading mt-22">{translate('loading.title')}</h2>
                <p className={`${styles.fact} text-subheading mt-4`}>{randomFact}</p>
            </div>
        </section>
    );
};

// Component will only render once as it is wrapped in memo.
export default memo(Loading);
