import React from 'react';
import Icon, { icons } from '../Icon/Icon';

import styles from './Chip.module.scss';

interface ChipProps {
    badge?:string,
    icon?:keyof typeof icons,
    label:string,
    className:string, // scope this,
    onClick:()=>void,
}

const Chip = ({ badge, className, icon, label, onClick }:ChipProps):JSX.Element => {
    return (
        <button type="button" className={`${styles.Chip} ${className}`} onClick={onClick}>
            {
                (badge)
                    ? <img className={styles.ChipBadge} src={badge} alt={label} />
                    : null
            }
            <span className="text-label text-dark uppercase">{label}</span>
            {
                (icon)
                    ? <Icon icon={icon} className={styles.ChipIcon} />
                    : null
            }
        </button>
    );
};

Chip.defaultProps = {
    badge: undefined,
    icon: undefined,
};

export default Chip;
