/**
 * Calculates the vh value similar to css. E.g. 20vh is calculateVH(20).
 * @param v - The vh to calculate to.
 * @returns {number}
 */
const calculateVH = (v: number): number => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
        return 0;
    }

    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    return (v * h) / 100;
};

export default calculateVH;
