import React, { ReactElement } from 'react';

import useGlobalContext, { USER_ALLOWANCE_LIMIT } from '../../../context/globalContext';
import { logo_small, name } from '../../../config/client_config.json';
import useTranslation from '../../../hooks/useTranslation';
import styles from './DesktopHeader.module.scss';

interface props {
    onBack?: (() => void) | null,
    onClose?: (() => void) | null
}

/**
 * Component to manage the desktop header with navigation elements.
 * @returns {ReactElement}
 */
const DesktopHeader = ({ onBack, onClose }: props): ReactElement => {
    // Get the translate functions.
    const { translate } = useTranslation();

    const { userAllowance } = useGlobalContext();

    // Translated button text.
    const backText = translate('global.buttons.back');
    const closeText = translate('global.buttons.close');

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <div className="flex items-center">
                    <img src={logo_small} alt={name} className={styles.logo} />
                    {(onBack && userAllowance() < USER_ALLOWANCE_LIMIT) ? (
                        <button type="button" onClick={onBack} className={styles.backButton}>
                            <img
                                src="/icons/chevron-left.svg"
                                alt={translate('global.buttons.back')}
                                className={styles.buttonIcon}
                                title={backText}
                            />
                            <span className="ml-3 text-dark">{backText}</span>
                        </button>
                    ) : null}
                </div>
                {onClose ? (
                    <button type="button" onClick={onClose}>
                        <img
                            src="/icons/cross.svg"
                            alt={closeText}
                            className={styles.buttonIcon}
                            title={closeText}
                        />
                    </button>
                ) : null}
            </div>
        </div>
    );
};

DesktopHeader.defaultProps = {
    onBack: undefined,
    onClose: undefined
};

export default DesktopHeader;
