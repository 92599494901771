import React, { ReactElement } from 'react';

import styles from './ImageComparison.module.scss';

import Icon from '../../../components/_ui/Icon/Icon';
import ImageComparisonSlider from '../../../components/_ui/ImageComparisonSlider/ImageComparisonSlider';
import useTranslation from '../../../hooks/useTranslation';
import useCompareYourFaceContext from '../context/compareYourFaceContext';
import useGlobalContext from '../../../context/globalContext';

const ImageComparison = (): ReactElement => {
    // Get the contexts
    const { badFutureImages, goodFutureImages } = useGlobalContext();
    const { showScores, alignedPhoto, relativeAge, handleShowScores, handleBack, toggleShare, showGoodFuture } = useCompareYourFaceContext();

    const activeBadFutureImage = badFutureImages[relativeAge]?.output_file;
    const activeGoodFutureImage = goodFutureImages[relativeAge]?.output_file;

    const activeBackgroundImage = (showGoodFuture) ? activeBadFutureImage : alignedPhoto;
    const activeForegroundImage = (showGoodFuture) ? activeGoodFutureImage : activeBadFutureImage;

    // Get the translation function.
    const { translate } = useTranslation();

    return (
        <div className={`${styles.slider} ${showScores ? styles.sliderShrink : ''}`}>
            <header className={styles.header}>
                <button className={styles.backButton} type="button" onClick={handleBack}>
                    <Icon width={18} height={32} icon="CHEVRON_LEFT" />
                </button>
                <h1 className={`${styles.heading} ${showScores ? styles.headingHide : ''} text-subheading`}>
                    {translate('compare_your_face.title')}
                </h1>
                {
                    (process.env.REACT_APP_SHARE_URL && process.env.REACT_APP_SHARE_URL !== '')
                        ? (
                            <button
                                type="button"
                                className={`${styles.shareButton} ${showScores ? styles.shareButtonShow : ''}`}
                                onClick={toggleShare}
                            >
                                {translate('compare_your_face.buttons.share')}
                            </button>
                        )
                        : null
                }
            </header>
            {
                (activeBackgroundImage && activeForegroundImage)
                    ? (
                        <ImageComparisonSlider
                            backgroundImage={activeBackgroundImage}
                            foregroundImage={activeForegroundImage}
                            releaseCallback={handleShowScores}
                        />
                    )
                    : null
            }

        </div>
    );
};

export default ImageComparison;
