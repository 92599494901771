import React, { ReactElement, FormEvent, useState, useEffect } from 'react';

import PageNavigation from '../../components/_ui/PageNavigation/PageNavigation';
import useGlobalContext, { USER_ALLOWANCE_LIMIT } from '../../context/globalContext';
import usePageContext from '../../context/pageContext';
import useTranslation from '../../hooks/useTranslation';
import pageStyles from '../scss/Pages.module.scss';
import styles from './LeadCaptureForm.module.scss';
import * as netlifyService from '../../services/netlify.service';
import useFetch from '../../services/hooks/useFetch';
import encodeFormData from './helpers/encodeFormData';

import Button from '../../components/_ui/Button/Button';
import EmailInput from '../../components/_ui/Forms/Inputs/EmailInput/EmailInput';
import TelInput from '../../components/_ui/Forms/Inputs/TelInput/TelInput';
import TextInput from '../../components/_ui/Forms/Inputs/TextInput/TextInput';
import FieldLabel from '../../components/_ui/Forms/FieldLabel/FieldLabel';
import useDesktopNavigationContext from '../../context/desktopNavigationContext';

type inputValue = undefined | string;

/**
 * The Lead capture form page.
 * @returns {reactElement}
 */
const LeadCaptureForm = (): ReactElement => {
    // Sets up the form states.
    const [name, setName] = useState<inputValue>(undefined);
    const [email, setEmail] = useState<inputValue>(undefined);
    const [phone, setPhone] = useState<inputValue>(undefined);
    const [company, setCompany] = useState<inputValue>(undefined);

    const { userAllowance } = useGlobalContext();

    // Get the next and previous page functions.
    const { onPrevPage, onNextPage } = usePageContext();

    // Get the function to desktop navigation functions.
    const { addNavigationFunctions } = useDesktopNavigationContext();

    // Gets the translate function.
    const { translate } = useTranslation();

    // If all fields exist then the form is valid.
    const isFormValid = !name || !email || !phone || !company;

    /**
     * Creates a hubspot contact.
     * @returns {promise | null}
     */
    const handleCreateContact = async () => {
        const body = encodeFormData({
            'form-name': 'contact',
            name: name as string,
            email: email as string,
            phone: phone as string,
            company: company as string
        });

        // Send and return hubspot request.
        return netlifyService.createContact(body);
    };

    /**
     * Get the API request states and function call.
     */
    const { state: { isLoading, error, success, data }, sendRequest } = useFetch(handleCreateContact);

    /**
     * On a successful form submission, go to the next page.
     */
    useEffect(() => {
        if (data && success === true) {
            onNextPage();
        }
    }, [data, success]);

    /**
     * Runs when the below form is submitted.
     * @param e - Form event.
     * @returns {void}
     */
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (isFormValid) {
            return;
        }

        sendRequest();
    };

    /**
     * Updates the name form state.
     * @param e - Input event
     */
    const handleUpdateName = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.target as HTMLInputElement;
        setName(value.trimStart());
    };

    /**
     * Updates the email form state.
     * @param e - Input event
     */
    const handleUpdateEmail = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.target as HTMLInputElement;
        setEmail(value);
    };

    /**
     * Updates the phone form state.
     * @param e - Input event
     */
    const handleUpdatePhone = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.target as HTMLInputElement;
        setPhone(value);
    };

    /**
     * Updates the Company form state.
     * @param e - Input event
     */
    const handleUpdateCompany = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.target as HTMLInputElement;
        setCompany(value);
    };

    // Add desktop navigation functions.
    useEffect(() => {
        addNavigationFunctions(onPrevPage, null);
    }, []);

    return (
        <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex overflow-y-auto`}>
            <div className={`${styles.inner} container-large`}>
                <header className={styles.header}>
                    {
                        (userAllowance() < USER_ALLOWANCE_LIMIT)
                            ? <PageNavigation onBack={onPrevPage} />
                            : null
                    }
                    <h1 className={`${styles.heading} text-subtitle`}>{translate('lead_capture_form.title')}</h1>
                    <span className={`${styles.subheading} text-paragraph text-slate`}>{translate('lead_capture_form.body_text')}</span>
                </header>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="w-full">
                        <FieldLabel
                            label={translate('lead_capture_form.fields.name.label')}
                            id="contact-name"
                            className="w-full"
                            isValid={!!name}
                            errorMessage={name === '' ? translate('lead_capture_form.fields.name.error') : ''}
                        >
                            <TextInput
                                id="contact-name"
                                value={name}
                                onChange={handleUpdateName}
                                isValid={!name}
                                autocomplete="name"
                                required
                                className={styles.input}
                                name="name"
                            />
                        </FieldLabel>
                        <FieldLabel
                            label={translate('lead_capture_form.fields.email.label')}
                            id="contact-email"
                            className="w-full mt-8"
                            isValid={!!email}
                            errorMessage={email === '' ? translate('lead_capture_form.fields.email.error') : ''}
                        >
                            <EmailInput
                                id="contact-email"
                                value={email}
                                onChange={handleUpdateEmail}
                                isValid={!email}
                                required
                                className={styles.input}
                                name="email"
                            />
                        </FieldLabel>
                        <FieldLabel
                            label={translate('lead_capture_form.fields.phone.label')}
                            id="contact-phone"
                            className="w-full mt-8"
                            isValid={!!phone}
                            errorMessage={phone === '' ? translate('lead_capture_form.fields.phone.error') : ''}
                        >
                            <TelInput
                                id="contact-phone"
                                value={phone}
                                onChange={handleUpdatePhone}
                                isValid={!phone}
                                required
                                className={styles.input}
                                name="phone"
                            />
                        </FieldLabel>
                        <FieldLabel
                            label={translate('lead_capture_form.fields.company.label')}
                            id="contact-company"
                            className="w-full mt-8"
                            isValid={!!company}
                            errorMessage={company === '' ? translate('lead_capture_form.fields.company.error') : ''}
                        >
                            <TextInput
                                id="contact-company"
                                value={company}
                                onChange={handleUpdateCompany}
                                isValid={!company}
                                required
                                className={styles.input}
                                name="company"
                            />
                        </FieldLabel>
                    </div>
                    {error ? (
                        <div className="text-note text-error pt-8">
                            There was an error submitting your contact details. Please try again.
                        </div>
                    ) : null}
                    <div className="flex-grow-0 flex-shrink-0 pt-8">
                        <Button type="submit" className="w-full" disabled={isLoading || isFormValid}>
                            {translate('lead_capture_form.buttons.submit')}
                        </Button>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default LeadCaptureForm;
