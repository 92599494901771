interface netlifyConfigInterface {
    headers: {
       'Accept-Encoding': 'gzip, deflate, br',
       'Content-Type': 'application/x-www-form-urlencoded'
        'x-mock-response-name'?: string,
    },
    mode: 'cors'
}

const netlifyConfig: netlifyConfigInterface = {
    headers: {
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    mode: 'cors',
};

export default netlifyConfig;
