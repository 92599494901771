/**
 * Get the dimensions of an image.
 * @param src - The src of the image.
 * @returns {object} - Object containing width, height and image object.
 */

interface returnType {
    height: number,
    width: number,
    img: HTMLImageElement
}

const getImageDimensions = (src: string): Promise<returnType> => new Promise((resolve, reject) => {
    // Create and set the src of a new image object.
    const img = new Image();
    img.src = src;

    // Onload resolve this promise and return the image and its dimensions.
    img.onload = () => {
        const { height, width } = img;
        resolve({ height, width, img });
    };

    // If the image doesn't load.
    img.onerror = reject;
});

export default getImageDimensions;
