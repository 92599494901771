import { useState, useCallback } from 'react';
import useGlobalContext from '../../../../context/globalContext';
import usePageContext from '../../../../context/pageContext';

type directionType = 'FORWARD' | 'BACKWARD';

interface useLifestyleNavigationInterface {
    handleNext: () => void,
    handlePrev: () => void,
    index: number,
    direction: directionType
}

/**
 * Manages the navigation between the lifestyle selector and questionnaire questions.
 * @returns {useLifestyleNavigationInterface}
 */
const useLifestyleNavigation = (): useLifestyleNavigationInterface => {
    // States for the current lifestyle view index.
    const [index, setIndex] = useState<number>(0);

    // The direction of travel.
    const [direction, setDirection] = useState<directionType>('FORWARD');

    // Gets the next page function.
    const { onNextPage } = usePageContext();

    // Get global data to set ageing only and the lifestyle questions selected.
    const { setAgeingOnly, lifestyleQuestions } = useGlobalContext();

    // Needed to set the classes first and the rendered page second for TransitionGroup.
    const debounceIndex = (i: number) => setTimeout(() => {
        setIndex(i);
    }, 100);

    /**
     * Moves the user to the next page.
     * @returns {void}
     */
    const handleNext = useCallback(() => {
        // Set the page direction.
        setDirection('FORWARD');

        // Get the new page index.
        const newIndex = index + 1;

        // If the new index is greater than the number of given questions, navigate to the next page.
        if (newIndex > lifestyleQuestions.length) {
            setTimeout(() => {
                setAgeingOnly(false);
                onNextPage();
            }, 250);
            return;
        }

        debounceIndex(newIndex);
    }, [index, lifestyleQuestions]);

    /**
     * Moves the user to the previous page.
     * @returns {void}
     */
    const handlePrev = () => {
        // Set the page direction.
        setDirection('BACKWARD');

        const newIndex = index - 1;

        // If the new page index is set before the splash screen, stop navigation.
        if (newIndex < 0) {
            return;
        }

        debounceIndex(newIndex);
    };

    return {
        handleNext,
        handlePrev,
        index,
        direction
    };
};

export default useLifestyleNavigation;
