// get api key from query string if it exists
import { api_key } from '../../config/client_config.json';
import { getApiKeyFromCookie } from './helpers/getApiKeyFromCookie';
import { getApiKeyFromQueryString } from './helpers/getApiKeyFromQueryString';

/**
 * check and get api key
 * 1. We check if the api key is in the env
 * 2. We check if the api key is in the query string - if it is we store it in a cookie and remove it from the url
 * 3. We check if the api key is in a cookie
 * 4. If we have an api key or not we return what we have
 * */
const checkApiKey = (): string | null => {
    let API_KEY: string | null = api_key;

    if (!API_KEY) API_KEY = getApiKeyFromQueryString();
    if (!API_KEY) API_KEY = getApiKeyFromCookie();

    return API_KEY;
};

/** We have to return a string here, this gets used in the api calls. */
const getApiKey = (): string => {
    let API_KEY: string | null = api_key;
    if (!API_KEY) API_KEY = getApiKeyFromCookie();

    return API_KEY || '';
};

export default {
    checkApiKey,
    getApiKey
};
