import getImageDimensions from '../helpers/getImageDimensions';
import getImageBase64 from '../../../helpers/getImageBase64';
import scaleImage from '../helpers/scaleImage';
import usePageContext from '../../../context/pageContext';
import useGlobalContext from '../../../context/globalContext';

const IMAGE_MAX_WIDTH = 1000;
const IMAGE_MAX_HEIGHT = 1000;

interface imageDimensionsObject {
    width: number,
    height: number,
    img: HTMLImageElement
}

interface useBase64FormatterInterface {
    handleFormatToBase64: (src: string) => void;
}

/**
 * Formats the provided image and dimensions to base64 for the CMF API. Image is stored in global context
 * when formatted.
 * @returns {useBase64FormatterInterface}
 */
const useBase64Formatter = (): useBase64FormatterInterface => {
    // Get the function to store the uploaded image.
    const { setUserData } = useGlobalContext();

    // Get the next page function.
    const { onNextPage } = usePageContext();

    /**
     * A function call to retrieve the uploaded image from a file input element.
     */
    const handleFormatToBase64 = async (src: string) => {
        // Work out the images dimensions.
        const { width, height, img } = await getImageDimensions(src) as imageDimensionsObject;

        let base64 = '';

        // If the image is less than the max width and height, get the base64.
        if (width <= IMAGE_MAX_WIDTH && height <= IMAGE_MAX_HEIGHT) {
            base64 = getImageBase64(img, width, height);
        } else {
            // If a dimension is greater than the limit, get the new scaled width and height.
            const { scaledWidth, scaledHeight } = scaleImage(width, height, IMAGE_MAX_WIDTH, IMAGE_MAX_HEIGHT);
            base64 = getImageBase64(img, scaledWidth, scaledHeight);
        }

        // Store the image and move to the next page.
        setUserData((state) => ({ ...state, uploadedPhoto: base64 }));
        onNextPage();
    };

    return {
        handleFormatToBase64
    };
};

export default useBase64Formatter;
