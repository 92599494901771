import React, { createContext, ReactElement, useState, ReactNode, useEffect, useCallback, useMemo, FC } from 'react';

import { flatten } from 'lodash';
import useGlobalContext from '../../../context/globalContext';
import usePageContext from '../../../context/pageContext';
import lifestyles from '../../../config/lifestyles.json';
import getWindowSize from '../../../helpers/getWindowSize';
import scrollTo from '../helpers/scrollTo';
import { roundToTheNearest } from '../../../helpers/maths/maths';
import useReadMore from './hooks/useReadMore';

import applyEffects, { effectResultInterface } from '../../../services/applyEffects.service';

import AgeingOnlyDescription from '../AgeingOnlyDescription/AgeingOnlyDescription';
import LifestyleDescription from '../LifestyleDescription/LifestyleDescription';
import calculateVH from './helpers/calculateVH';
import useFetch, { reducerState as useFetchState } from '../../../services/hooks/useFetch';
import auth from '../../../lib/Auth/auth';

interface props {
    children: ReactNode
}

interface useCompareYourFaceContextInterface {
    showScores: boolean,
    handleShowScores: () => void,

    showShare: boolean,
    toggleShare: () => void,

    readMoreSate: {
        show: boolean,
        data: any
    },
    openReadMore: (data: any) => void,
    closeReadMore: () => void,
    clearReadMore: () => void,

    handleBack: () => void,

    uploadedPhoto: string,
    alignedPhoto: string,

    showGoodFuture: boolean,
    handleShowGoodFuture: () => void

    DescriptionToRender: FC,

    showDelayOverlay: boolean,
    setShowDelayOverlay: (show: boolean) => void,

    displayRelativeAgeToggle: boolean,

    relativeAge: number,
    toggleRelativeAge: (position: 1 | 0) => void
}

const CompareYourFaceContext = createContext<useCompareYourFaceContextInterface | null>(null);

export const CompareYourFaceProvider = ({ children, ...rest }: props): ReactElement => {
    // Get global context bits.
    const { lifestyleScores, ageingOnly, lifestyleAnswers, badFutureImages, setGoodFutureImages, userData } = useGlobalContext();

    // Gets the next and previous pages.
    const { jumpToPage } = usePageContext();

    // Gets the read more functions.
    const { readMoreSate, openReadMore, closeReadMore, clearReadMore } = useReadMore();

    // Get relevant user data.
    const { uploadedPhoto, alignedPhoto, sessionId, age } = userData;

    // Whether or not to show the lifestyle scores.
    const [showScores, setShowScores] = useState(getWindowSize().windowWidth > 678);
    const handleShowScores = () => setShowScores(true);

    // Whether or not to show the share panel.
    const [showShare, setShowShare] = useState(false);
    const toggleShare = () => setShowShare((state) => !state);

    const [showDelayOverlay, setShowDelayOverlay] = useState<boolean>(false);

    // If the good or bad lifestyle scores should be shown.
    const [showGoodFuture, setShowGoodFuture] = useState(false);

    // TODO: Is there a way to streamline this?
    // If +10 or +20 images are shown
    const [relativeAge, setRelativeAge] = useState(0);
    const toggleRelativeAge = (position: 1 | 0) => setRelativeAge(position);

    // TODO: make this work whether bad or good future is active
    const displayRelativeAgeToggle = badFutureImages.length > 1;

    // If the user has answered some lifestyle answers.
    const hasLifestyleAnswers = useMemo(() => Object.keys(lifestyleAnswers).length > 0, [lifestyleAnswers]);

    // Which scoring view to display.
    const DescriptionToRender = useMemo(() => ((ageingOnly || hasLifestyleAnswers === false) ? AgeingOnlyDescription : LifestyleDescription), [ageingOnly, hasLifestyleAnswers]);

    /*
     * Sends a request to the CMF API which simulates a 'good future' result.
     */
    const applyImprovedImageEffects = async (relativeAgeEffect:string) => {
        const { ageModifier } = lifestyleScores;

        // Calculate new age with modifier.
        let roundedAge = roundToTheNearest(age + ageModifier, 5);

        // If the new age is greater than 70, set the age to 70.
        if (roundedAge > 70) {
            roundedAge = 70;
        }

        // Generate an improved list of effect IDs
        const improvedLifestyleChoices = Object.keys(lifestyleAnswers).map((key) => {
            const { score } = lifestyleAnswers[key];

            const lifestyleQuestion = lifestyles.questions.find((question: lifestyleQuestionInterface) => {
                return question.id === key;
            }) as lifestyleQuestionInterface | undefined;

            const lifestyleQuestionChoice = lifestyleQuestion?.choices.find((choice) => {
                return choice.score === score + 1;
            }) || lifestyleAnswers[key];

            return lifestyleQuestionChoice;
        });

        const improvedIterationCounts:number[] = [];

        const improvedEffectIds = flatten(improvedLifestyleChoices.map((choice) => {
            choice.effect_ids.forEach(() => {
                improvedIterationCounts.push(choice.iteration_count);
            });

            return choice.effect_ids;
        }));

        // Get the next +10 and +20 aged photos.
        const ageList = [roundedAge];

        // Convert the effect IDs to a comma seperated string.
        const ages = ageList.join(',');
        const iterations = [...improvedIterationCounts, '1'].join(',');

        const orderedEffectIds = improvedEffectIds.sort((a, b) => {
            // TODO: machine learning models needs to go first because of a wierd API bug
            if (typeof a === 'string') {
                return -1;
            }
            return 1;
        });

        // Create API form data.
        const formData = new FormData();
        formData.append('action', 'transform');
        formData.append('api_key', auth.getApiKey());
        formData.append('session_id', sessionId);
        formData.append('effects[age]', ages);
        formData.append('upload_aligned', 'True');
        formData.append('b64_image', uploadedPhoto);
        formData.append('effects[dont_cache]', 'false');
        // formData.append('iteration_count', iterations);
        formData.append('iteration_count', '1');

        // formData.set('effects[effect_id]', [relativeAgeEffect, ...orderedEffectIds].join(','));
        formData.set('effects[effect_id]', [relativeAgeEffect].join(','));


        // Send API data.
        return applyEffects(formData);
    };

    // Get the API request status details.
    const { state: { data: goodFuturePlusTenData, success: goodFuturePlusTenSuccess, error: goodFuturePlusTenError }, sendRequest: sendImprovedRequestPlusTen } = useFetch(() => applyImprovedImageEffects('10_years_older'), false);
    const { state: { data: goodFuturePlusTwentyData, success: goodFuturePlusTwentySuccess, error: goodFuturePlusTwentyError }, sendRequest: sendImprovedRequestPlusTwenty } = useFetch(() => applyImprovedImageEffects('20_years_older'), false);

    // Listens to the good future API calls, hides the delay screen once it's all done
    useEffect(() => {
        if (goodFuturePlusTenData && goodFuturePlusTenSuccess && goodFuturePlusTwentyData && goodFuturePlusTwentySuccess) {
            const goodFuturePlusTenResponse = goodFuturePlusTenData.data[0] as effectResultInterface[];
            const goodFuturePlusTwentyResponse = goodFuturePlusTwentyData.data[0] as effectResultInterface[];

            setGoodFutureImages([goodFuturePlusTenResponse, goodFuturePlusTwentyResponse]);

            // TODO: shouldn't need to do this if we can safely set the default properly when we init the useState.
            // setImprovedAgedImage(goodFuturePlusTenResponse[0].output_file);

            if (showDelayOverlay) {
                setShowDelayOverlay(false);
                handleShowGoodFuture();
            }
        }
    }, [goodFuturePlusTenData, goodFuturePlusTwentyData, showDelayOverlay]);

    /**
     * Scrolls the view back to the top of the scores or page depending on if the user is on mobile or not.
     */
    const scrollToTop = () => {
        const isMobile = getWindowSize().windowWidth < 678;

        if (isMobile) {
            // Get the viewport vh and minus the size of the header.
            const offset = Math.max(calculateVH(70) - 68);
            scrollTo('._js-compare-you-face-wrapper', offset);
            return;
        }

        scrollTo('._js-score');
    };

    /**
     * Takes the user back to the bad future scores panel or previous page.
     * @returns {void}
     */
    const handleBack = useCallback(() => {
        if (ageingOnly || hasLifestyleAnswers === false) {
            jumpToPage('LIFESTYLE_INSTRUCTIONS');
            return;
        }

        if (showGoodFuture) {
            scrollToTop();
            setShowGoodFuture(false);
            return;
        }

        jumpToPage('LIFESTYLE_INSTRUCTIONS');
    }, [showGoodFuture]);

    /**
     * Takes the user to the next good future scores panel or to the next page.
     * @returns {void}
     */
    const handleShowGoodFuture = () => {
        scrollToTop();
        setShowGoodFuture(true);
    };

    // Logs the users scores and effect ID's depending on the env setting.
    useEffect(() => {
        // TODO: Only trigger these calls if we don't have the data already, or if we don't think it's in transit
        sendImprovedRequestPlusTen();
        sendImprovedRequestPlusTwenty();
        if (process.env.REACT_APP_LOG_EFFECT_ID_API_DATA === 'true') {
            // console.log({ lifestyleScores });
        }
    }, []);

    const value = {
        showScores,
        handleShowScores,

        showShare,
        toggleShare,

        readMoreSate,
        openReadMore,
        closeReadMore,
        clearReadMore,

        handleBack,

        DescriptionToRender,

        uploadedPhoto,
        alignedPhoto,

        showGoodFuture,
        handleShowGoodFuture,

        relativeAge,
        toggleRelativeAge,

        displayRelativeAgeToggle,

        showDelayOverlay,
        setShowDelayOverlay,

    };

    return (
        <CompareYourFaceContext.Provider value={value} {...rest}>
            {children}
        </CompareYourFaceContext.Provider>
    );
};

const useCompareYourFaceContext = (): useCompareYourFaceContextInterface => {
    const context = React.useContext(CompareYourFaceContext);
    if (context === undefined) {
        throw new Error('useCompareYourFaceContext must be used within a CompareYourFaceProvider');
    }
    return context as useCompareYourFaceContextInterface;
};

export default useCompareYourFaceContext;
