import React, { createContext, ReactElement, useState, ReactNode, useMemo } from 'react';

import calculateScores from '../helpers/calculateScores/calculateScores';
import convertStringToBool from '../helpers/convertStringToBool';
import { effectResultInterface } from '../services/applyEffects.service';

interface props {
    children: ReactNode
}

export interface userDataInterface {
    sessionId: string,
    uploadedPhoto: string,
    alignedPhoto: string,
    age: number
}

type setUserDataType = ((previousState: userDataInterface) => userDataInterface) | userDataInterface;

interface lifestyleScoresInterface {
    currentScore: number,
    potentialScore: number,
    effectIds: (number|string)[],
    iterationCounts: number[],
    ageModifier: number
}

interface contextValues {

    userData: userDataInterface,
    setUserData: (userData: setUserDataType) => void,

    userAllowance: () => number,
    setUserAllowance: () => void,
    userAllowanceCheckpoints: string[],

    ageingOnly: boolean,
    setAgeingOnly: (option: boolean) => void,

    lifestyleAnswers: lifestyleAnswersInterface,
    setLifestyleAnswers: (answer: lifestyleAnswersInterface) => void,

    lifestyleQuestions: lifestyleQuestionInterface[],
    setLifestyleQuestions: (questions: lifestyleQuestionInterface[]) => void,

    lifestyleScores: lifestyleScoresInterface,

    badFutureImages:effectResultInterface[],
    setBadFutureImages: (images: any) => void,

    goodFutureImages:effectResultInterface[],
    setGoodFutureImages: (images: any) => void,

    uploadError: null | 'UPLOAD_ERR' | 'API_ERR',
    setUploadError: (error: contextValues['uploadError']) => void

    clearData: () => void
}

export const USER_ALLOWANCE_LIMIT = (process.env.REACT_APP_USAGE_ALLOWANCE_LIMIT) ? parseInt(process.env.REACT_APP_USAGE_ALLOWANCE_LIMIT) : 5;
export const ENABLE_CONTACT_FORM = (process.env.REACT_APP_ENABLE_CONTACT_FORM) ? convertStringToBool(process.env.REACT_APP_ENABLE_CONTACT_FORM) : false;
export const ENABLE_AGEING_ONLY = (process.env.REACT_APP_ENABLE_AGEING_ONLY) ? convertStringToBool(process.env.REACT_APP_ENABLE_AGEING_ONLY) : false;

const GlobalContext = createContext<contextValues | null>(null);

export const GlobalProvider = ({ children, ...rest }: props): ReactElement => {
    // If the image upload to the CMF API failed.
    const [uploadError, setUploadError] = useState<contextValues['uploadError']>(null);

    // Store the bad future images
    const [badFutureImages, setBadFutureImages] = useState<effectResultInterface[]>([]);

    // Store the good future images
    const [goodFutureImages, setGoodFutureImages] = useState<effectResultInterface[]>([]);

    // If the user has chosen the path of aging only.
    const [ageingOnly, setAgeingOnly] = useState(false);

    // Stores information for the current user.
    const [userData, setUserData] = useState<userDataInterface>({
        sessionId: '',
        uploadedPhoto: '',
        alignedPhoto: '',
        age: 0
    });

    const enableUserAllowanceLimit = convertStringToBool(process.env.REACT_APP_ENABLE_USER_ALLOWANCE_LIMIT);

    const userAllowance = () => {
        if (window.localStorage) {
            const allowance = window.localStorage.getItem('userAllowance') || '0';
            return parseInt(allowance);
        }
        return 0;
    };

    const setUserAllowance = () => {
        if (enableUserAllowanceLimit && window.localStorage) {
            window.localStorage.setItem('userAllowance', (parseInt(localStorage.getItem('userAllowance') || '0') + 1).toString());
        }
    };

    const userAllowanceCheckpoints = [
        'SPLASH',
        'PHOTO_INSTRUCTIONS',
        'UPLOADING',
        'LIFESTYLE_INSTRUCTIONS',
        'AGEING',
    ];

    // Stores the selected lifestyle categories.
    const [lifestyleQuestions, setLifestyleQuestions] = useState<lifestyleQuestionInterface[]>([]);

    // The answers to the questionnaire.
    const [lifestyleAnswers, setLifestyleAnswers] = useState<lifestyleAnswersInterface>({});

    // The scores for the lifestyle questionnaire. { currentScore: 3.6, potentialScore: 8.8, effectIds: [1, 2, 3] }
    const lifestyleScores = useMemo(() => calculateScores(lifestyleAnswers, ageingOnly), [lifestyleAnswers, ageingOnly]);

    // Resets the global context data to reuse the app.
    const clearData = () => {
        setLifestyleQuestions([]);
        setLifestyleAnswers({});
        setBadFutureImages([]);
        setGoodFutureImages([]);
        setUploadError(null);

        setUserData({
            sessionId: '',
            uploadedPhoto: '',
            alignedPhoto: '',
            age: 0
        });
    };

    const value = {

        userData,
        setUserData,

        userAllowance,
        setUserAllowance,
        userAllowanceCheckpoints,

        ageingOnly,
        setAgeingOnly,

        lifestyleAnswers,
        setLifestyleAnswers,

        lifestyleQuestions,
        setLifestyleQuestions,

        lifestyleScores,

        badFutureImages,
        setBadFutureImages,

        goodFutureImages,
        setGoodFutureImages,

        uploadError,
        setUploadError,

        clearData
    };

    return (
        <GlobalContext.Provider value={value} {...rest}>
            {children}
        </GlobalContext.Provider>
    );
};

const useGlobalContext = (): contextValues => {
    const context = React.useContext(GlobalContext);
    if (context === undefined) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context as contextValues;
};

export default useGlobalContext;
