import React, { ReactElement } from 'react';

import { CompareYourFaceProvider } from './context/compareYourFaceContext';

import CompareYourFaceLayout from './CompareYourFaceLayout/CompareYourFaceLayout';

/**
 * The compare your face page.
 * @returns {ReactElement}
 */
const CompareYourFace = (): ReactElement => {
    return (
        <CompareYourFaceProvider>
            <CompareYourFaceLayout />
        </CompareYourFaceProvider>
    );
};

export default CompareYourFace;
