/* eslint-disable react/no-danger */
import React, { ReactElement, useEffect } from 'react';

import useTranslation from '../../hooks/useTranslation';
import usePageContext from '../../context/pageContext';
import useGlobalContext, { ENABLE_CONTACT_FORM } from '../../context/globalContext';
import pageStyles from '../scss/Pages.module.scss';
import styles from './CompanyProfile.module.scss';
import useDesktopNavigationContext from '../../context/desktopNavigationContext';

import PageNavigation from '../../components/_ui/PageNavigation/PageNavigation';
import Button from '../../components/_ui/Button/Button';

/**
 * The company profile page.
 */
const CompanyProfile = (): ReactElement => {
    // Get translation function.
    const { translate } = useTranslation();

    const { addNavigationFunctions } = useDesktopNavigationContext();

    // Get the set user data function.
    const { clearData } = useGlobalContext();

    // Get the next and previous pages.
    const { onNextPage, onPrevPage, jumpToPage } = usePageContext();

    // Add desktop navigation functions.
    useEffect(() => {
        addNavigationFunctions(onPrevPage, null);
    }, []);

    /**
     * Clears the users data and jumps to the start of the app.
     */
    const handleRestart = () => {
        clearData();
        jumpToPage('START');
    };


    return (
        <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex bg-white overflow-auto`}>
            <div className="container-large my-auto">
                <PageNavigation onBack={onPrevPage} />
                <div
                    className={`${styles.description} wysiwyg`}
                    dangerouslySetInnerHTML={{ __html: translate('company_profile.body_text') }}
                />
                <div className={styles.buttonWrapper}>
                    {
                        (ENABLE_CONTACT_FORM)
                            ? (
                                <Button className={styles.button} onClick={onNextPage}>
                                    {translate('company_profile.buttons.contact')}
                                </Button>
                            )
                            : (
                                <Button className={styles.button} onClick={handleRestart}>
                                    {translate('company_profile.buttons.restart')}
                                </Button>
                            )
                    }
                </div>
            </div>
        </main>
    );
};

export default CompanyProfile;
