import React, { ReactElement, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ANIMATION_MEDIUM } from '../../../config/animation_config';
import styles from './PanelAnimation.module.scss';

interface props {
    children: ReactNode,
    showPanel: boolean,
    onExited?: () => void
}

/**
 * Animates a panel ind and out.
 * @returns {ReactElement}
 */
const PanelAnimation = ({ children, showPanel, onExited }: props): ReactElement => (
    <CSSTransition
        in={showPanel}
        timeout={ANIMATION_MEDIUM}
        mountOnEnter
        unmountOnExit
        onExited={onExited}
        classNames={{
            appear: styles.panelAnimation,
            enter: styles.a_slideUp,
            enterActive: styles.a_slideUpActive,
            enterDone: styles.a_slideUpDone,
            exit: styles.a_slideDown,
            exitActive: styles.a_slideDownActive,
            exitDone: styles.a_slideDownDone,
        }}
    >
        {children}
    </CSSTransition>
);

PanelAnimation.defaultProps = {
    onExited: undefined
};

export default PanelAnimation;
