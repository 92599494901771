import React, { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import useTranslation from '../../../hooks/useTranslation';
import { ANIMATION_MEDIUM } from '../../../config/animation_config';

import styles from '../../../app/App.module.scss';
import delayStyles from './Delay.module.scss';

interface props {
    show:boolean,
    message?:string
}

const Delay = ({ show, message }: props): ReactElement|null => {
    // Get the translationFunction
    const { translate } = useTranslation();

    return (
        <CSSTransition
            in={show}
            timeout={ANIMATION_MEDIUM}
            classNames={{
                enter: styles.a_fadeUp,
                enterActive: styles.a_fadeUpActive,
                exit: styles.a_fadeDown,
                exitActive: styles.a_fadeDownActive
            }}
            mountOnEnter
            unmountOnExit
        >
            <div className={delayStyles.delay}>
                <span className="text-white">{translate(message as string)}</span>
            </div>
        </CSSTransition>
    );
};

Delay.defaultProps = {
    message: 'delay.default'
};

export default Delay;
