import { useState, useRef, useEffect } from 'react';

interface useHintInterface {
    showHint: boolean,
    handleHideHint: () => void,
    clearHintTimeout: () => void
}

const HINT_DELAY = 3000;

/**
 * Controls to manage the visibility of a hint with a time delay.
 * @returns {object}
 */
const useHint = (): useHintInterface => {
    // Stores the hint visibility state
    const [showHint, setShowHint] = useState<boolean>(false);

    // Stores the hint set timeout.
    const hintTimeout = useRef<any>();

    /**
     * Hides the hint if it's currently visible.
     * @returns {void}
     */
    const handleHideHint = () => {
        if (showHint === false) {
            return;
        }

        // Sets the show hint to false.
        setShowHint(false);
    };

    /**
     * Clears the show hint timeout if it's still there.
     * @returns {void}
     */
    const clearHintTimeout = () => {
        if (hintTimeout.current === null) {
            return;
        }

        clearTimeout(hintTimeout.current);
        hintTimeout.current = null;
    };

    /**
     * Create a setTimeout to show the hint after the delay.
     */
    useEffect(() => {
        hintTimeout.current = setTimeout(() => {
            setShowHint(true);
        }, HINT_DELAY);

        // Clear the timeout if it's still around.
        return () => {
            clearTimeout(hintTimeout.current);
            hintTimeout.current = null;
        };
    }, []);

    return {
        showHint,
        handleHideHint,
        clearHintTimeout
    };
};

export default useHint;
