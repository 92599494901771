import queryString from 'query-string';
import { removeApiKeyFromUrl } from './removeApiKeyFromUrl';
import { storeApiKeyInCookie } from './storeApiKeyInCookie';

export const getApiKeyFromQueryString = (): string | null => {
    const parsed = queryString.parse(window.location.search);

    if (!('API_KEY' in parsed)) {
        return null;
    }

    const API_KEY = parsed.API_KEY as string;

    if (!API_KEY) return null;


    storeApiKeyInCookie(parsed.API_KEY as string);
    removeApiKeyFromUrl();
    return parsed.API_KEY as string;
};
