import React, { ReactElement, useEffect, useState } from 'react';

import ToggleBubble from './ToggleBubble/ToggleBubble';

type positionsType = 1 | 0;

interface props {
    onChange: (position: positionsType) => void,
    optionOne: string,
    optionTwo: string,
}

/**
 * Component that toggles between two values.
 */
const Toggle = ({ onChange, optionOne, optionTwo }: props): ReactElement => {
    // Stores the toggles position state.
    const [position, setPosition] = useState<positionsType>(0);

    // Toggles the position between 1 and 0.
    const togglePosition = () => setPosition((state: positionsType) => (state === 0 ? 1 : 0));

    // When the position changes.
    useEffect(() => {
        onChange(position);
    }, [onChange, position]);

    return (
        <div className="bg-white rounded-40px text-dark">
            <ToggleBubble className="-mr-2" onClick={togglePosition} isOn={position === 0}>
                {optionOne}
            </ToggleBubble>
            <ToggleBubble onClick={togglePosition} isOn={position === 1}>
                {optionTwo}
            </ToggleBubble>
        </div>
    );
};

export default Toggle;
