import React, { ReactElement } from 'react';

import useTranslation from '../../../../hooks/useTranslation';
import styles from './QuestionChoice.module.scss';
import animationStyles from '../helpers/getLifestyleAnimationClasses/questionChoiceAnimations.module.scss';

import SelectableCard from '../../../_ui/SelectableCard/SelectableCard';
import useGlobalContext from '../../../../context/globalContext';

interface props {
    question: lifestyleQuestionInterface,
    clickCallback: () => void
}

const QuestionChoices = ({ question, clickCallback }: props): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();

    const { lifestyleAnswers, setLifestyleAnswers } = useGlobalContext();

    /**
     * When a choice is clicked, store the choice ID and move to the next question / page.
     * @param choiceScore - The ID of the choice that was selected.
     */
    const toggleAnswer = (answer: lifestyleChoiceInterface) => {
        const questionAnswersClone = { ...lifestyleAnswers };
        const answeredBefore = lifestyleAnswers[question.id];

        // If the user de-selects the current answer, set the answer to null.
        if (answeredBefore && answeredBefore.id === question.id) {
            delete questionAnswersClone[question.id];

            setLifestyleAnswers(questionAnswersClone);
            return;
        }

        // Adds the new answer to the list of answers.
        questionAnswersClone[question.id] = answer;
        setLifestyleAnswers(questionAnswersClone);
        clickCallback();
    };

    return (
        <div className={`${animationStyles.choiceList} container-small z-10`}>
            <h2 className="text-subtitle">{translate(question.title)}</h2>
            <ul>
                {question.choices.map((choice) => {
                    const choiceTitle = translate(choice.title);

                    const handleSelectChoice = () => toggleAnswer(choice);

                    const checked = lifestyleAnswers[question.id] && lifestyleAnswers[question.id].id === choice.id;

                    return (
                        <li key={choice.id} className="mt-4">
                            <SelectableCard
                                onClick={handleSelectChoice}
                                checked={checked}
                                className={styles.choice}
                            >
                                <img src={choice.icon} alt={choiceTitle} className={styles.image} />
                                <div className="ml-2.5">
                                    <h3 className="text-label text-dark">{choiceTitle}</h3>
                                    <p className="text-note">{translate(choice.excerpt)}</p>
                                </div>
                            </SelectableCard>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default QuestionChoices;
