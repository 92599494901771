import React, { createContext, ReactElement, FC, useState, useMemo, ReactNode } from 'react';

import useDesktopNavigationContext from './desktopNavigationContext';

import Splash from '../pages/Splash/Splash';
import Start from '../pages/Start/Start';
import PhotoInstructions from '../pages/PhotoInstructions/PhotoInstructions';
import CompanyProfile from '../pages/CompanyProfile/CompanyProfile';
import LeadCaptureForm from '../pages/LeadCaptureForm/LeadCaptureForm';
import LifestyleInstructions from '../pages/LifestyleInstructions/LifestyleInstructions';
import CompareYourFace from '../pages/CompareYourFace/CompareYourFace';
import Success from '../pages/Success/Success';
import Ageing from '../pages/Ageing/Ageing';
import Uploading from '../pages/Uploading/Uploading';

interface props {
    children: ReactNode
}

export type pageDirectionType = 'FORWARD' | 'BACKWARD';

export type pageKeysType = keyof typeof pages;

interface contextValues {
    jumpToPage: (index: pageKeysType) => void,
    onNextPage: () => void,
    onPrevPage: () => void,
    PageToRender: FC,
    currentPage: pageKeysType,
    pageDirection: pageDirectionType
}

const pages = {
    SPLASH: Splash,
    START: Start,
    PHOTO_INSTRUCTIONS: PhotoInstructions,
    UPLOADING: Uploading,
    LIFESTYLE_INSTRUCTIONS: LifestyleInstructions,
    AGEING: Ageing,
    COMPARE_YOUR_FACE: CompareYourFace,
    COMPANY_PROFILE: CompanyProfile,
    LEAD_CAPTURE_FORM: LeadCaptureForm,
    SUCCESS: Success
};

const PageContext = createContext<contextValues | null>(null);

export const PageProvider = ({ children, ...rest }: props): ReactElement => {
    // States for the current page and the page travel direction.
    const [currentPage, setCurrentPage] = useState<pageKeysType>('SPLASH');
    const [pageDirection, setPageDirection] = useState<pageDirectionType>('FORWARD');

    // Get function to clear any stored desktop navigation functions.
    const { clearNavigationFunctions } = useDesktopNavigationContext();

    // The page component to render.
    const PageToRender = useMemo(() => pages[currentPage], [currentPage]);

    // Get the current page index as a number.
    const getCurrentPageIndex = () => Object.keys(pages).indexOf(currentPage);

    // Needed to set the classes first and the rendered page second for TransitionGroup.
    const debounceCurrentPage = (key: pageKeysType) => setTimeout(() => {
        clearNavigationFunctions();
        setCurrentPage(key);
    }, 100);

    /**
     * Moves the user to the next page.
     * @returns {void}
     */
    const onNextPage = () => {
        // Set the page direction.
        setPageDirection('FORWARD');

        // Get the new page index.
        const pageKeys = Object.keys(pages) as [pageKeysType];
        const newPageIndex = getCurrentPageIndex() + 1;

        // If the new page index is more than the number of available pages, stop navigation.
        if (newPageIndex > (pageKeys.length - 1)) {
            return;
        }

        debounceCurrentPage(pageKeys[newPageIndex]);
    };

    /**
     * Moves the user to the previous page.
     * @returns {void}
     */
    const onPrevPage = () => {
        // Set the page direction.
        setPageDirection('BACKWARD');

        // Get the new page index.
        const pageKeys = Object.keys(pages) as [keyof typeof pages];
        const newPageIndex = getCurrentPageIndex() - 1;

        // If the new page index is set before the splash screen, stop navigation.
        if (newPageIndex < 1) {
            return;
        }

        debounceCurrentPage(pageKeys[newPageIndex]);
    };

    /**
     * Jump to any given page.
     * @param {number} index - Page number to jump to.
     * @returns {void}
     */
    const jumpToPage = (index: keyof typeof pages) => {
        // If the index is not a given page, return.
        if (typeof pages[index] === 'undefined') {
            return;
        }

        const newPageIndex = Object.keys(pages).indexOf(index);
        const directionOfJump = getCurrentPageIndex() < newPageIndex ? 'FORWARD' : 'BACKWARD';

        // Set the page direction.
        setPageDirection(directionOfJump);
        debounceCurrentPage(index);
    };

    const value = {
        jumpToPage,
        onNextPage,
        onPrevPage,
        PageToRender,
        currentPage,
        pageDirection
    };

    return (
        <PageContext.Provider value={value} {...rest}>
            {children}
        </PageContext.Provider>
    );
};

const usePageContext = (): contextValues => {
    const context = React.useContext(PageContext);
    if (context === undefined) {
        throw new Error('usePageContext must be used within a PageProvider');
    }
    return context as contextValues;
};

export default usePageContext;
