interface dataInterface {
    [key: string]: string | number
}

const encodeFormData = (data: dataInterface): string => {
    return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
};

export default encodeFormData;
