import React, { ReactElement, ReactNode } from 'react';
import useTranslation from '../../../hooks/useTranslation';

import styles from './PanelNavigation.module.scss';

interface props {
    children?: ReactNode,
    onClose: () => void
}

/**
 * The navigation header for a panel.
 */
const PanelNavigation = ({ children, onClose }: props): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();

    return (
        <div className="flex justify-between">
            {children || <div />}
            <button type="button" className={styles.button} onClick={onClose}>
                <img
                    className={styles.cross}
                    src="/icons/cross.svg"
                    alt={translate('global.buttons.close')}
                />
            </button>
        </div>
    );
};

PanelNavigation.defaultProps = {
    children: null
};

export default PanelNavigation;
