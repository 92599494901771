interface constraintsInterface {
    video?: any
}

const getDeviceMedia = async (constraints: constraintsInterface) => {
    // If the broswer doesn't support mediaDevice.
    if (typeof navigator.mediaDevices === 'undefined') {
        return null;
    }

    // Make the access request.
    try {
        const mediaDevices = await navigator.mediaDevices.getUserMedia(constraints);
        return mediaDevices;
    } catch (error) {
        console.warn('Camera not available');
        return null;
    }
};

export default getDeviceMedia;
