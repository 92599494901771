import React, { ReactElement } from 'react';
import useTranslation from '../../hooks/useTranslation';

import { name, logo_large } from '../../config/client_config.json';
import Icon from '../../components/_ui/Icon/Icon';

import styles from '../scss/Pages.module.scss';
import splashStyles from '../Splash/Splash.module.scss';
import TranslationSwitcher from '../../components/TranslationSwitcher/TranslationSwitcher';

const RestrictedAccessPage = (): ReactElement => {
    // Get the translation function.
    const { translate, exists } = useTranslation();

    return (
        <main data-testid="restrictedAccessPage" className={`${styles.page} ${splashStyles.wrapper}`}>
            <div className={splashStyles.header}>
                <div className={splashStyles.roundBackground} />
                <img className={splashStyles.splashLogo} src={logo_large} alt={name} />
            </div>
            <div className={splashStyles.content}>
                <div className="flex gap-2 content-center justify-center">
                    <Icon icon="WARNING_OUTLINE" width={32} height={32} className="text-error" />
                    <div>
                        <span className="text-heading text-dark block" style={{ lineHeight: '1.3rem' }}>{translate('restricted_access.title')}</span>
                        <p className="text-metadata block">{translate('restricted_access.message')}</p>
                    </div>
                </div>
            </div>
            <TranslationSwitcher />
        </main>
    );
};

export default RestrictedAccessPage;
