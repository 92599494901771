import { useReducer } from 'react';

interface readMoreStateInterface {
    show: boolean,
    data: any
}

interface readMoreActionInterface {
    type: 'OPEN' | 'CLOSE' | 'CLEAR',
    data: any | null
}

interface useReadMoreInterface {
    readMoreSate: readMoreStateInterface,
    openReadMore: (data: any) => void,
    closeReadMore: () => void,
    clearReadMore: () => void
}

const initialReadMoreState = {
    show: false,
    data: null
};

const readMoreReducer = (state: readMoreStateInterface, action: readMoreActionInterface) => {
    switch (action.type) {
    case 'OPEN':
        return {
            show: true,
            data: action.data
        };
    case 'CLOSE':
        return {
            ...state,
            show: false
        };
    case 'CLEAR':
        return {
            show: false,
            data: null
        };
    default:
        console.error('No state provided.');
        return state;
    }
};

/**
 * State management for opening and closing a read more.
 * @returns {useReadMoreInterface}
 */
const useReadMore = (): useReadMoreInterface => {
    const [readMoreSate, showMoreDispatch] = useReducer(readMoreReducer, initialReadMoreState);

    const openReadMore = (data: any) => showMoreDispatch({ type: 'OPEN', data });
    const closeReadMore = () => showMoreDispatch({ type: 'CLOSE', data: null });
    const clearReadMore = () => showMoreDispatch({ type: 'CLEAR', data: null });

    return {
        readMoreSate,

        openReadMore,
        closeReadMore,
        clearReadMore
    };
};

export default useReadMore;
