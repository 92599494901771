import React, { ReactElement, FormEvent } from 'react';

interface props {
    id: string,
    required?: boolean,
    isValid?: boolean,
    className?: string,
    value?: string | number | readonly string[],
    defaultValue?: string | number | readonly string[]
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    [key: string]: any,
}

const numberPattern = '[0-9]*';

const NumberInput = ({ id, required, isValid, className, value, defaultValue, onChange, ...rest }: props): ReactElement => (
    <input
        id={id}
        className={`${className} bg-white text-paragraph text-dark`}
        type="number"
        min="0"
        inputMode="numeric"
        aria-required={required}
        aria-invalid={isValid === false}
        value={value}
        pattern={numberPattern}
        defaultValue={defaultValue}
        onChange={onChange}
        autoComplete="tel"
        title="Only enter numbers, +, -, or ()"
        {...rest}
    />
);

NumberInput.defaultProps = {
    required: false,
    className: undefined,
    isValid: false,
    value: undefined,
    defaultValue: undefined,
    onChange: undefined
};

export default NumberInput;
