import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './translations/translations.json';

import randomNumberBetween from '../helpers/randomNumberBetween';
import { LANGUAGE_LOCAL_STORAGE_KEY } from '../constants/languageLocalStorageKey';

type Resources = { [key: string]: { translation: any } };
const resources:Resources = {};

let defaultSelectedLanguage = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) || process.env.REACT_APP_TRANSLATION_DEFAULT || 'en';
/** if we cant find the default translation in the file, then pick the first in the list */
if (!translations.find((t) => t === defaultSelectedLanguage)) {
    // eslint-disable-next-line prefer-destructuring
    defaultSelectedLanguage = translations[0];
}

translations
    .forEach(async (lang) => {
        // eslint-disable-next-line import/no-dynamic-require, @typescript-eslint/no-var-requires, global-require
        const translationObj = require(`./translations/translation_${lang}.json`);
        resources[lang] = { translation: translationObj };
    });

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultSelectedLanguage,
        fallbackLng: process.env.REACT_APP_TRANSLATION_DEFAULT || 'en', // https://www.i18next.com/principles/fallback#language-fallback
    });

// TODO: Convert the below function to a plugin - https://www.i18next.com/misc/creating-own-plugins
// eslint-disable-next-line
// @ts-ignore
i18n.TRandom = (slug: string): string => {
    const value = i18n.t(slug, {
        returnObjects: true,
    });

    if (Array.isArray(value)) {
        return value[randomNumberBetween((value.length - 1))];
    }
    // eslint-disable-next-line
// @ts-ignore
    return value;
};

