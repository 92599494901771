import React, { ReactElement, ReactNode } from 'react';

import styles from './Toggle.module.scss';

interface props {
    className?: string,
    isOn: boolean,
    children: ReactNode
    onClick?: () => void,
}

const ToggleBubble = ({ className, isOn, children, onClick }: props): ReactElement => {
    return (
        <button className={`${styles.button} ${className}`} type="button" onClick={onClick}>
            <span className={`${styles.bubble} ${isOn ? styles.bubbleShow : ''}`} />
            <span className={`${styles.buttonText} ${isOn ? styles.buttonTextAlt : ''}`}>
                {children}
            </span>
        </button>
    );
};

ToggleBubble.defaultProps = {
    className: '',
    onClick: undefined
};

export default ToggleBubble;
