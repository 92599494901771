import cmfConfig from './config/cmf_config';
import getCmfApiUrl from './helpers/getCmfApiUrl';

/**
 * Uploads an image to the API.
 * @param {string} body - The body data for the fetch request.
 * @returns {promise}
 */
const uploadImage = async (body: string | FormData) :Promise<Response> => {
    const url = getCmfApiUrl();

    const config = {
        ...cmfConfig,
        method: 'POST',
        body
    };

    if (process.env.REACT_APP_USE_API_MOCK === 'true') {
        config.headers['x-mock-response-name'] = 'Upload Image - 200';
        // config.headers['x-mock-response-name'] = 'Upload Image - 403 - Invalid API Key';
    }

    return fetch(url, config);
};

export default uploadImage;
