import React, { ReactElement, FormEvent } from 'react';

interface props {
    id: string,
    required?: boolean,
    isValid?: boolean,
    className?: string,
    value?: string | number | readonly string[],
    defaultValue?: string | number | readonly string[]
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    autocomplete?: string,
    [key: string]: any,
}

const TextInput = ({ id, required, isValid, className, value, defaultValue, onChange, autocomplete, ...rest }: props): ReactElement => (
    <input
        id={id}
        className={`${className} bg-white text-paragraph text-dark`}
        type="text"
        aria-required={required}
        aria-invalid={isValid === false}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        autoComplete={autocomplete}
        {...rest}
    />
);

TextInput.defaultProps = {
    required: false,
    className: undefined,
    isValid: false,
    value: undefined,
    defaultValue: undefined,
    onChange: undefined,
    autocomplete: undefined
};

export default TextInput;
