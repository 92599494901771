import React, { ReactElement } from 'react';

import styles from './Rating.module.scss';
import getRatingColour from './helpers/getRatingColour';
import useTranslation from '../../../hooks/useTranslation';

interface props {
    value: number,
    className?: string,
}

/**
 * The rating component that uses a percentage to display the score.
 * @returns {ReactElement}
 */
const Rating = ({ value, className }: props): ReactElement => {
    // Get the translation function.
    const { translate } = useTranslation();

    // Gets the rating bar colour.
    const { textColour, bgColour } = getRatingColour(value);

    // Converts the value to a percentage string.
    const percentage = `${value}%`;

    let scoreBarClassName = `${textColour} text-label ${styles.scoreBar}`;

    // If the value is less than 15%, shift the score bar to the right.
    if (value < 20) {
        scoreBarClassName += ` ${styles.scoreBarShiftRight}`;
    }

    return (
        <div className={`${styles.bar} ${className}`}>
            <div className={`${styles.rating} ${bgColour}`} style={{ width: percentage }}>
                <span className={scoreBarClassName}>
                    {percentage}
                    {value === 100 ? (
                        <img
                            className={styles.goldStar}
                            src="/icons/gold-star.svg"
                            alt={translate('global.images.gold_star')}
                        />
                    ) : null}
                </span>
            </div>
        </div>
    );
};

Rating.defaultProps = {
    className: ''
};

export default Rating;
