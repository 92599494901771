const MAX_IMAGE_UPLOAD = 1;

export const fileTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
];

/**
 * Checks if the file type provided is valid/allowed.
 * @param file - The file to validate.
 * @returns {boolean} - Is the file type valid.
 */
export const isFileTypeValid = (file: File): boolean => fileTypes.includes(file.type);

const validateFileUpload = (files: FileList | null): false | File => {
    // If there are no files.
    if (!files || files.length === 0) {
        return false;
    }

    // If more than the max number of images has been uploaded.
    if (files.length > MAX_IMAGE_UPLOAD) {
        return false;
    }

    // Get the uploaded image.
    const image = files[0];

    // Is the image a valid / allowed file type.
    if (isFileTypeValid(image) === false) {
        return false;
    }

    return image;
};

export default validateFileUpload;
