import { useState, useMemo, useCallback, FormEvent } from 'react';

import useTranslation from '../../../hooks/useTranslation';

interface useManageAgeInputInterface {
    age: number | '' | null,
    isAgeValid: boolean,
    errorMessage: string,
    handleUpdateAge: (e: FormEvent<HTMLInputElement>) => void
}

const MAX_AGE = 100;

const useManageAgeInput = (): useManageAgeInputInterface => {
    const [age, setAge] = useState<number | '' | null>(null);

    // Get the translate function.
    const { translate } = useTranslation();

    // Checks the age is valid.
    const isAgeValid = typeof age === 'number' && age <= MAX_AGE;

    // Gets an error message if the age is invalid.
    const errorMessage = useMemo(() => {
        if (age === '') {
            return translate('start.fields.age.error.noAge');
        }

        if (typeof age === 'number') {
            if (age > MAX_AGE) {
                return `${translate('start.fields.age.error.age_greater_than')} ${MAX_AGE}`;
            }
        }

        return '';
    }, [age]);

    /**
     * Function that handles the age input on change.
     */
    const handleUpdateAge = useCallback((e: FormEvent<HTMLInputElement>) => {
        let { value } = e.target as HTMLInputElement;

        // If the value has been cleared, set empty string.
        if (value === '') {
            setAge(value);
            return;
        }

        // Remove space before and after the value.
        value = value.trim();

        // If the user tries to use decimol, return early.
        if (value.includes('.')) {
            return;
        }

        // Convert the input value to a number.
        const valueAsNumber = parseInt(value, 10);

        // If the value could not be converted, return early.
        if (Number.isNaN(valueAsNumber)) {
            return;
        }

        // Set the verified value.
        setAge(valueAsNumber);
    }, []);

    return {
        age,
        isAgeValid,
        errorMessage,
        handleUpdateAge
    };
};

export default useManageAgeInput;
