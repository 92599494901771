import React, { useMemo } from 'react';
import auth from '../lib/Auth/auth';
import RestrictedAccessPage from '../pages/RestrictedAccessPage/RestrictedAccessPage';

interface props {
    children: React.ReactNode;
}

const APIRestricted = ({ children }: props): JSX.Element | null => {
    const hasAccess = useMemo(() => auth.checkApiKey(), []);

    if (hasAccess) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }

    return (<RestrictedAccessPage />);
};

export default APIRestricted;
