import React, { ReactNode, ReactElement } from 'react';

import styles from './SelectableCard.module.scss';

import Icon from '../Icon/Icon';

interface props {
    className?: string,
    checked?: boolean,
    disabled?: boolean,
    onClick: () => void,
    children: ReactNode
}

const SelectableCard = ({ className, checked, disabled, onClick, children }: props): ReactElement => (
    <article className={`${styles.selectableCard} ${checked ? styles.selectableCardChecked : ''} ${className} ${disabled ? styles.selectableCardDisabled : ''}`}>
        {
            (!disabled)
                ? (
                    <div className={`${styles.checkIcon} ${checked ? styles.checkIconShow : ''}`}>
                        <Icon icon="CHECK" width="0.61rem" height="0.61rem" />
                    </div>
                )
                : null
        }
        {children}
        {
            (!disabled)
                ? <button className={styles.toggleButton} type="button" onClick={onClick}>Toggle card</button>
                : null
        }

    </article>
);

SelectableCard.defaultProps = {
    className: '',
    checked: false,
    disabled: false,
};

export default SelectableCard;
