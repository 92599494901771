import React, { createContext, ReactElement, useState, ReactNode, useCallback } from 'react';
import DesktopHeader from '../components/_ui/DesktopHeader/DesktopHeader';

interface props {
    children: ReactNode
}

interface desktopHeaderFunctionsInterface {
    onBack: (() => void) | null,
    onClose: (() => void) | null
}

type callbackType = (() => void) | null;

interface useDesktopNavigationContextInterface {
    addNavigationFunctions: (backCallback: callbackType, closeCallback: callbackType) => void,
    clearNavigationFunctions: () => void
}

const DesktopNavigationContext = createContext<useDesktopNavigationContextInterface | null>(null);

const defaultFunctions = {
    onBack: null,
    onClose: null
};

export const DesktopNavigationProvider = ({ children, ...rest }: props): ReactElement => {
    const [desktopHeaderFunctions, setDesktopHeaderFunctions] = useState<desktopHeaderFunctionsInterface>(defaultFunctions);

    const { onBack, onClose } = desktopHeaderFunctions;

    const addNavigationFunctions = (backCallback: callbackType = null, closeCallback: callbackType = null) => {
        setDesktopHeaderFunctions({
            onBack: backCallback,
            onClose: closeCallback
        });
    };

    const clearNavigationFunctions = useCallback(() => setDesktopHeaderFunctions(defaultFunctions), []);

    const value = {
        addNavigationFunctions,
        clearNavigationFunctions
    };

    return (
        <DesktopNavigationContext.Provider value={value} {...rest}>
            <div className="flex flex-col h-full">
                <DesktopHeader onBack={onBack} onClose={onClose} />
                <div className="flex-grow relative z-20">
                    {children}
                </div>
            </div>
        </DesktopNavigationContext.Provider>
    );
};

const useDesktopNavigationContext = (): useDesktopNavigationContextInterface => {
    const context = React.useContext(DesktopNavigationContext);
    if (context === undefined) {
        throw new Error('useDesktopNavigationContext must be used within a DesktopNavigationProvider');
    }
    return context as useDesktopNavigationContextInterface;
};

export default useDesktopNavigationContext;
