import choiceStyles from './questionChoiceAnimations.module.scss';
import selectorStyles from './lifestyleSelectorAnimations.module.scss';

interface getQuestionAnimationClassesInterface {
    enter: string,
    enterActive: string,
    exit: string,
    exitActive: string,
    exitDone: string
}

type questionDirectionType = 'FORWARD' | 'BACKWARD';

/**
 * Returns the animation classes for a CSSTransition component for the question animation.
 * @param currentPage - The current page.
 * @param direction - The direction of page travel (forward/backward).
 * @returns {getQuestionAnimationClassesType}
 */
const getLifestyleAnimationClasses = (direction: questionDirectionType, isSelector = false): getQuestionAnimationClassesInterface => {
    if (direction === 'FORWARD') {
        return {
            enter: choiceStyles.a_slideInRight,
            enterActive: choiceStyles.a_slideInRightActive,
            exit: isSelector ? selectorStyles.a_slideOutLeft : choiceStyles.a_slideOutLeft,
            exitActive: isSelector ? selectorStyles.a_slideOutLeftActive : choiceStyles.a_slideOutLeftActive,
            exitDone: isSelector ? selectorStyles.a_slideOutLeftDone : choiceStyles.a_slideOutLeftDone,
        };
    }

    return {
        enter: isSelector ? selectorStyles.a_slideInLeft : choiceStyles.a_slideInLeft,
        enterActive: isSelector ? selectorStyles.a_slideInLeftActive : choiceStyles.a_slideInLeftActive,
        exit: choiceStyles.a_slideOutRight,
        exitActive: choiceStyles.a_slideOutRightActive,
        exitDone: choiceStyles.a_slideOutRightDone,
    };
};

export default getLifestyleAnimationClasses;
