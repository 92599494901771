import React, { ReactElement, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import usePageContext from '../../context/pageContext';
import useFetch from '../../services/hooks/useFetch';
import useGlobalContext from '../../context/globalContext';
import useCompareYourFaceContext from '../CompareYourFace/context/compareYourFaceContext';
import applyEffects, { effectResultInterface } from '../../services/applyEffects.service';
import pageStyles from '../scss/Pages.module.scss';
import { roundToTheNearest } from '../../helpers/maths/maths';
import styles from './Ageing.module.scss';

import Loading from '../../components/Panels/Loading/Loading';
import useTranslation from '../../hooks/useTranslation';
import Button from '../../components/_ui/Button/Button';
import auth from '../../lib/Auth/auth';

const LOADING_STATE_DELAY = 2000;

/**
 * Loading page whilst an API request is sent to age the photo.
 * @returns {ReactElement}
 */
const Ageing = (): ReactElement => {
    // Gets data from the global context.
    const { userData, setUserData, lifestyleScores, setBadFutureImages, setGoodFutureImages, setUserAllowance } = useGlobalContext();

    // Get the translation function.
    const { translate } = useTranslation();

    // Gets the next page function.
    const { onNextPage } = usePageContext();

    // Sends a request to the CMF API.
    const applyImageEffects = async (relativeAgeEffect:string) => {
        const { effectIds, iterationCounts, ageModifier } = lifestyleScores;
        const { sessionId, age, uploadedPhoto } = userData;

        // Calculate new age with modifier.
        let roundedAge = roundToTheNearest(age + ageModifier, 5);

        // If the new age is greater than 70, set the age to 70.
        if (roundedAge > 70) {
            roundedAge = 70;
        }

        // Get the next +10 and +20 aged photos.
        const ageList = [roundedAge];

        // Convert the effect IDs to a comma seperated string.
        const ages = ageList.join(',');
        const iterations = [...iterationCounts, '1'].join(',');

        const orderedEffectIds = effectIds.sort((a, b) => {
            // TODO: machine learning models needs to go first because of a wierd API bug
            if (typeof a === 'string') {
                return -1;
            }
            return 1;
        });

        // Create API form data.
        const formData = new FormData();
        formData.append('action', 'transform');
        formData.append('api_key', auth.getApiKey());
        formData.append('session_id', sessionId);
        formData.append('effects[age]', ages);
        formData.append('upload_aligned', 'True');
        formData.append('b64_image', uploadedPhoto);
        formData.append('effects[dont_cache]', 'false');
        formData.append('iteration_count', iterations);

        formData.set('effects[effect_id]', [relativeAgeEffect, ...orderedEffectIds].join(','));

        // Send API data.
        return applyEffects(formData);
    };

    // Get the API request status details.
    const { state: { data: dataTen, success: successTen, error: errorTen }, sendRequest: sendRequestTen } = useFetch(() => applyImageEffects('10_years_older'), false, LOADING_STATE_DELAY);
    const { state: { data: dataTwenty, success: successTwenty, error: errorTwenty }, sendRequest: sendRequestTwenty } = useFetch(() => applyImageEffects('20_years_older'), false, LOADING_STATE_DELAY);

    // On successfull data return, update the aged images and move to the next page.
    useEffect(() => {
        if (dataTen && dataTwenty && successTen && successTwenty) {
            const effectResponseTen = dataTen.data[0] as effectResultInterface[];
            const effectResponseTwenty = dataTwenty.data[0] as effectResultInterface[];
            const agedImageList = [effectResponseTen, effectResponseTwenty];

            setBadFutureImages(agedImageList);

            setUserData((state) => ({
                ...state,
                alignedPhoto: dataTen.data[1].output_file
            }));

            setUserAllowance();

            onNextPage();
        }
    }, [dataTen, dataTwenty, successTen, successTwenty]);

    // Send the apply effects request on load.
    useEffect(() => {
        setBadFutureImages([]);
        setGoodFutureImages([]);

        sendRequestTen();
        sendRequestTwenty();
    }, []);

    // If there was an error, show the restart elements.
    if (errorTen || errorTwenty) {
        return (
            <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex`}>
                <div className="container-xsmall my-auto">
                    <img
                        className={styles.errorImage}
                        src="/images/error-exclamation-mark.svg"
                        alt={translate('global.images.face_with_question_mark')}
                    />
                    <section className={styles.description}>
                        <h1 className="text-heading">
                            {translate('ageing.error.title')}
                        </h1>
                        <p className="text-subheading mt-4">
                            {translate('ageing.error.body_text')}
                        </p>
                        <div className="mt-6">
                            <Button onClick={() => { sendRequestTen(); sendRequestTwenty(); }} className="w-full">
                                {translate('ageing.error.buttons.retry')}
                            </Button>
                        </div>
                    </section>
                </div>
            </main>
        );
    }

    return (
        <Fragment>
            <Helmet>
                <link rel="preload" href="images/error-exclamation-mark.svg" as="image" />
            </Helmet>
            <Loading lottie="FACE_SCAN" />
        </Fragment>
    );
};

export default Ageing;
