import React, { ReactElement, Fragment } from 'react';

import styles from './Checkbox.module.scss';

import Icon from '../../../Icon/Icon';

interface props {
    id?: string,
    checked: boolean,
    onChange: () => void
}

const Checkbox = ({ checked, id, onChange }: props): ReactElement => {
    return (
        <Fragment>
            <input id={id} className={styles.input} type="checkbox" checked={checked} onChange={onChange} />
            <span className={styles.checkbox}>
                <Icon icon="CHECK" className={`${styles.icon} ${checked ? styles.iconShow : ''}`} />
            </span>
        </Fragment>
    );
};

Checkbox.defaultProps = {
    id: undefined
};

export default Checkbox;
