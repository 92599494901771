import React, { ReactElement, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import useTranslation from '../../../hooks/useTranslation';
import styles from './Lifestyles.module.scss';
import useLifestyleNavigation from './hooks/useLifestyleNavigation';
import { ANIMATION_MEDIUM } from '../../../config/animation_config';
import usePageContext from '../../../context/pageContext';
import useGlobalContext from '../../../context/globalContext';
import getLifestyleAnimationClasses from './helpers/getLifestyleAnimationClasses/getLifestyleAnimationClasses';

import PanelNavigation from '../../_ui/PanelNavigation/PanelNavigation';
import ProgressBar from '../../_ui/ProgressBar/ProgressBar';
import LifestyleSelector from './LifestyleSelector/LifestyleSelector';
import Button from '../../_ui/Button/Button';
import QuestionChoices from './QuestionChoices/QuestionChoices';

interface props {
    onClose: () => void
}

const Lifestyles = ({ onClose }: props): ReactElement => {
    // Gets the next page function.
    const { jumpToPage } = usePageContext();

    const { lifestyleQuestions, lifestyleAnswers, setAgeingOnly } = useGlobalContext();

    const { translate } = useTranslation();

    const { handleNext, handlePrev, index, direction } = useLifestyleNavigation();

    const currentQuestion = useMemo(() => (index > 0 ? lifestyleQuestions[index - 1] : null), [lifestyleQuestions, index]);

    const hasBeenAnswered = currentQuestion ? lifestyleAnswers[currentQuestion.id] : null;

    const steps = lifestyleQuestions.length + 1;

    const handleStartQuestionnaire = () => {
        if (lifestyleQuestions.length === 0) {
            jumpToPage('AGEING');
            setAgeingOnly(true);
            return;
        }

        handleNext();
    };

    const transitionKey = currentQuestion ? currentQuestion.id.toUpperCase() : 'SELECTOR';

    const ComponentToRender = useMemo(() => (currentQuestion
        ? <QuestionChoices question={currentQuestion} clickCallback={handleNext} />
        : <LifestyleSelector />),
    [currentQuestion, handleNext]);

    const classNames = getLifestyleAnimationClasses(direction, index === 0);

    return (
        <aside className={styles.wrapper}>
            <div className={styles.inner}>
                <header className="flex-grow-0 flex-shrink-0 container-small w-full">
                    <PanelNavigation onClose={onClose}>
                        <h2 className="text-heading">{translate('lifestyle_questions.title')}</h2>
                    </PanelNavigation>
                    <ProgressBar steps={steps} currentStep={index} className="mt-4" />
                </header>
                <TransitionGroup component={null}>
                    <CSSTransition
                        in
                        key={transitionKey}
                        timeout={ANIMATION_MEDIUM}
                        classNames={classNames}
                        mountOnEnter
                        unmountOnExit
                    >
                        {ComponentToRender}
                    </CSSTransition>
                </TransitionGroup>
                <div className={styles.actions}>
                    <div className={`${styles.startButton} ${currentQuestion ? 'opacity-0 pointer-events-none' : ''}`}>
                        <Button onClick={handleStartQuestionnaire} className="w-full">
                            {translate('lifestyle_questions.buttons.start')}
                        </Button>
                    </div>
                    <div className={`${styles.questionnaireButtons} ${currentQuestion === null ? 'opacity-0 pointer-events-none' : ''}`}>
                        {/* If it's the first question, disable the back button. */}
                        {index > 0 ? (
                            <Button onClick={handlePrev} primary={false}>
                                {translate('lifestyle_questions.buttons.back')}
                            </Button>
                        ) : <div />}
                        <Button onClick={handleNext}>
                            {/* If the question has already been answered. */}
                            {hasBeenAnswered
                                ? translate('lifestyle_questions.buttons.next')
                                : translate('lifestyle_questions.buttons.skip')}
                        </Button>
                    </div>
                </div>

            </div>
        </aside>
    );
};

export default Lifestyles;
