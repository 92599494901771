import React, { memo, ReactElement, Fragment } from 'react';

import useGlobalContext from '../../context/globalContext';
import useTranslation from '../../hooks/useTranslation';

import Rating from '../_ui/Rating/Rating';
import LifestyleReadMore from '../_ui/LifestyleReadMore/LifestyleReadMore';

interface props {
    onReadMore: (choice: lifestyleChoiceInterface) => void
}

/**
 * Shows the users current lifetyle score.
 * @returns {ReactElement}
 */
const BadFuture = ({ onReadMore }: props): ReactElement | null => {
    // Get the translation function.
    const { translate } = useTranslation();

    // Get the lifestyle scores and the users lifestyle answers.
    const { lifestyleScores, lifestyleAnswers } = useGlobalContext();

    // Get the current lifestyle score.
    const currentScore = lifestyleScores ? lifestyleScores.currentScore : 0;

    // Store the score grade to get the relevant score description.
    let scoreDescription = '';

    // Get the score grade.
    switch (true) {
    case currentScore === 100:
        scoreDescription = 'top';
        break;
    case currentScore >= 70:
        scoreDescription = 'good';
        break;
    case currentScore >= 40:
        scoreDescription = 'average';
        break;
    case currentScore >= 10:
        scoreDescription = 'poor';
        break;
    case currentScore < 10:
    default:
        scoreDescription = 'bottom';
        break;
    }

    // If there are no answers from the questionnaire.
    if (Object.keys(lifestyleAnswers).length === 0) {
        return null;
    }

    return (
        <Fragment>
            <h2 className="text-heading">{translate('compare_your_face.scores.bad_future.title')}</h2>
            <p className="mt-2 text-subheading">
                {translate(`compare_your_face.scores.bad_future.${scoreDescription}`)}
            </p>
            <Rating value={currentScore} className="mt-4" />
            <section className="mt-8">
                <h2 className="text-heading">{translate('compare_your_face.scores.bad_future.subtitle')}</h2>
                <ul className="mt-4">
                    {Object.keys(lifestyleAnswers).map((questionId) => {
                        const choice = lifestyleAnswers[questionId];

                        return (
                            <li key={questionId} className="mt-4">
                                <LifestyleReadMore lifestyleChoice={choice} onReadMore={onReadMore} />
                            </li>
                        );
                    })}
                </ul>
            </section>
        </Fragment>
    );
};

// Component will only render once as it is wrapped in memo.
export default memo(BadFuture);
