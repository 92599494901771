/**
 * Converts a string to a boolean based on the presence of 'true'
 * @param string - string.
 * @returns {boolean} - The cast boolean result.
 */
const convertStrToBool = (originalString?: string): boolean => {
    if (!originalString) {
        return false;
    }

    const res = (originalString === 'true');

    return res;
};

export default convertStrToBool;
