import React, { ReactElement } from 'react';

import useTranslation from '../../../hooks/useTranslation';
import styles from './LifestyleReadMore.module.scss';

interface props {
    lifestyleChoice: lifestyleChoiceInterface,
    showGoodFuture?: boolean,
    className?: string,
    onReadMore: (choice: lifestyleChoiceInterface) => void
}

/**
 * Simple read more component for the lifestyle choice provided.
 * @returns {ReactElement}
 */
const LifestyleReadMore = ({ lifestyleChoice, showGoodFuture, className, onReadMore }: props): ReactElement => {
    // Get the translationFunction
    const { translate } = useTranslation();

    // Function to call the read more function.
    const handleReadMore = () => onReadMore(lifestyleChoice);

    // Select and translate the title.
    const choiceTitle = showGoodFuture
        ? translate(lifestyleChoice.potential.title)
        : translate(lifestyleChoice.title);

    // Select and translate the description.
    const description = showGoodFuture
        ? translate(lifestyleChoice.potential.description)
        : translate(lifestyleChoice.description);

    return (
        <div key={lifestyleChoice.id} className={`flex items-start ${className}`}>
            <div className={styles.image}>
                <img
                    src={showGoodFuture ? lifestyleChoice.potential.icon : lifestyleChoice.icon}
                    alt={choiceTitle}
                />
            </div>
            <div className="flex-grow min-w-0 ml-4">
                <h3 className="text-label text-dark">{choiceTitle}</h3>
                <p className={`${styles.description} text-label`}>
                    {description}
                </p>
                <button
                    type="button"
                    className="text-label text-primary mt-1"
                    onClick={handleReadMore}
                >
                    {translate('read_more_buttons.read_more')}
                </button>
            </div>
        </div>
    );
};

LifestyleReadMore.defaultProps = {
    showGoodFuture: false,
    className: ''
};

export default LifestyleReadMore;
