import React, { ReactElement, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import usePageContext from '../../context/pageContext';
import useFetch from '../../services/hooks/useFetch';
import useGlobalContext from '../../context/globalContext';
import uploadImage from '../../services/uploadImage.service';
import cmfExampleImage from '../../data/cmfExampleImage';

import Loading from '../../components/Panels/Loading/Loading';
import auth from '../../lib/Auth/auth';

const LOADING_STATE_DELAY = 2000;

/**
 * Loading page whilst an API request is sent to age the photo.
 * @returns {ReactElement}
 */
const Uploading = (): ReactElement => {
    // Gets data from the global context.
    const { userData, setUserData, setUploadError } = useGlobalContext();
    const { uploadedPhoto } = userData;

    // Gets the next page function.
    const { onNextPage, onPrevPage } = usePageContext();

    // Sends a request to the CMF API.
    const submitPhoto = () => {
        // Set up data for the upload request.
        const formData = new FormData();
        formData.append('action', 'upload_image');
        formData.append('api_key', auth.getApiKey());
        formData.append('b64_image', uploadedPhoto);

        // Add the base64 to the API request data and send.
        return uploadImage(formData);
    };

    // Get the API request status details.
    const { state: { data, success, error, errorResponseJson } } = useFetch(submitPhoto, true, LOADING_STATE_DELAY);

    // On successfull data return, update the aged images and move to the next page.
    useEffect(() => {
        if (success && data) {
            setUserData((state) => ({
                ...state,
                sessionId: data.session_id,
                uploadedPhoto: process.env.REACT_APP_USE_DEMO_IMAGE === 'true' ? cmfExampleImage : uploadedPhoto
            }));
            setUploadError(null);
            onNextPage();
        }
    }, [data, success, uploadedPhoto, setUserData]); // eslint-disable-line react-hooks/exhaustive-deps

    // If there was an error, show the restart elements.
    useEffect(() => {
        if (!error === true) return;

        if (errorResponseJson.detail.code === 100 && errorResponseJson.detail.message === 'No faces found in image') {
            setUploadError('UPLOAD_ERR');
            onPrevPage();
            return;
        }

        setUploadError('API_ERR');
        onPrevPage();
    }, [error]);

    return (
        <Fragment>
            <Helmet>
                <link rel="preload" href="images/error-exclamation-mark.svg" as="image" />
            </Helmet>
            <Loading lottie="FACE_SCAN" />
        </Fragment>
    );
};

export default Uploading;
