import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

const PreloadLifeStyleImages = (): ReactElement => {
    return (
        <Helmet>
            <link rel="preload" href="/icons/lifestyle/alcohol/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/alcohol/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/alcohol/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/dental/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/dental/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/dental/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/diet/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/diet/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/diet/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/drugs/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/drugs/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/drugs/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/exercise/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/exercise/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/exercise/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/financial/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/financial/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/financial/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/pollution/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/pollution/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/pollution/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/skincare/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/skincare/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/skincare/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sleep/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sleep/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sleep/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/smoking/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/smoking/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/smoking/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/stress/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/stress/good.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/stress/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sun/normal.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sun/bad.svg" as="image" />
            <link rel="preload" href="/icons/lifestyle/sun/bad.svg" as="image" />
        </Helmet>
    );
};

export default PreloadLifeStyleImages;
