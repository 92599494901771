import React, { ReactElement } from 'react';

import { name, logo_small } from '../../../config/client_config.json';
import useTranslation from '../../../hooks/useTranslation';
import styles from './PageNavigation.module.scss';

interface props {
    onBack?: () => void,
    showLogo?: boolean
}

/**
 * The navigation header for a page.
 */
const PageNavigation = ({ onBack, showLogo }: props): ReactElement => {
    const { translate } = useTranslation();
    return (
        <div className={styles.wrapper}>
            {onBack ? (
                <button type="button" className={styles.button} onClick={onBack}>
                    <img className={styles.chevron} src="/icons/chevron-left.svg" alt={translate('global.buttons.back')} />
                </button>
            ) : <div />}
            {showLogo === true ? (
                <img className={styles.image} src={logo_small} alt={name} />
            ) : null}
        </div>
    );
};

PageNavigation.defaultProps = {
    onBack: undefined,
    showLogo: true
};

export default PageNavigation;
