/**
 * Removes an item from the supplied array using the provided callback.
 * @param array - The array to remove an item from.
 * @param findCallback - The findIndex callback function.
 * @returns {array}
 */
export const removeArrayItem = (array: any[], findCallback: (a: any) => boolean): any[] => {
    const arrayClone = [...array];

    // Get the index of the item to remove.
    const arrayIndex = arrayClone.findIndex(findCallback);

    // Is the item in the array.
    if (arrayIndex < 0) {
        return arrayClone;
    }

    // Remove the item from the array.
    arrayClone.splice(arrayIndex, 1);

    return arrayClone;
};

/**
 * Removes an item from the supplied array using the provided index.
 * @param array - The array to remove an item from.
 * @param arrayIndex - The index of the item to remove.
 * @returns {array}
 */
export const removeArrayItemByIndex = (array: any[], arrayIndex: number): any[] => {
    const arrayClone = [...array];

    // Is the item in the array.
    if (arrayIndex < 0) {
        return arrayClone;
    }

    // Remove the item from the array.
    arrayClone.splice(arrayIndex, 1);

    return arrayClone;
};

/**
 * Removes duplicates from the passed in array.
 * @param array The array to check to remove duplicates from.
 * @returns {any[]}
 */
export const removeArrayDuplicates = (array: any[]): any[] => {
    const freshArray: any[] = [];

    array.forEach((item) => {
        if (freshArray.includes(item) === false) {
            freshArray.push(item);
        }
    });

    return freshArray;
};
