import { RefObject, useEffect } from 'react';

import getImageBase64 from '../../../../helpers/getImageBase64';
import useVideoMediaStream from '../../../../pages/PhotoInstructions/hooks/useVideoMediaStream';

interface useTakePhotoInterface {
    isCameraAvailable: boolean,
    handleTakePhoto: () => void
}

/**
 * Takes a screenshot from the video element.
 * @param videoRef - React ref for the video element.
 * @param handlePhotoTaken - When the image has been taken.
 * @returns {useTakePhotoInterface}
 */
const useTakePhoto = (videoRef: RefObject<HTMLVideoElement>, handlePhotoTaken: (src: string) => void): useTakePhotoInterface => {
    // State to store the media stream object in.
    const { mediaStream } = useVideoMediaStream();

    // The video HTML element.
    const video = videoRef.current;

    /**
     * When media stream state is updated, link it to the video element.
     */
    useEffect(() => {
        // Set up the camera feed for the video element.
        const setUpCamera = async () => {
            // If the media stream has not been set yet, reate one and store it.
            if (mediaStream === null) {
                return;
            }

            // If there is no video element.
            if (video === null) {
                return;
            }

            // Add the media stream to the vedeo element.
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
                video.play();
            };
        };

        setUpCamera();
    }, [mediaStream]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Takes a single screenshot from the media stream using the video element.
     * @returns {void}
     */
    const handleTakePhoto = () => {
        if (video === null) {
            return;
        }

        // Gets the height and width of the video element.
        const width = video.offsetWidth;
        const height = video.offsetHeight;

        // Send the screenshot back via callback.
        handlePhotoTaken(getImageBase64(video, width, height));
    };

    return {
        isCameraAvailable: mediaStream !== null,
        handleTakePhoto
    };
};

export default useTakePhoto;
