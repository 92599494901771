import React, { ReactElement } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import usePageContext from '../context/pageContext';
import { ANIMATION_MEDIUM } from '../config/animation_config';
import getPageAnimationClasses from './helpers/getPageAnimationClasses';
import APIRestricted from '../components/APIRestricted';

/**
 * Renders the main content of the app.
 * @returns {ReactElement}
 */
const App = (): ReactElement => {
    const { PageToRender, currentPage, pageDirection } = usePageContext();

    // Gets the splash, start and generic page class names for the transition component.
    const classNames = getPageAnimationClasses(currentPage, pageDirection);

    return (
        <APIRestricted>
            <TransitionGroup component={null}>
                <CSSTransition
                    in
                    key={currentPage}
                    timeout={ANIMATION_MEDIUM}
                    classNames={classNames}
                    mountOnEnter
                    unmountOnExit
                >
                    <PageToRender />
                </CSSTransition>
            </TransitionGroup>
        </APIRestricted>
    );
};

export default App;
