import { useEffect, useState } from 'react';

import getDeviceMedia from '../helpers/getDeviceMedia';

interface useTakePhotoInterface {
    mediaStream: MediaStream | null
}

/**
 * Creates and stores a media stream object.
 * @returns {useTakePhotoInterface}
 */
const useVideoMediaStream = (): useTakePhotoInterface => {
    // State to store the media stream object in.
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

    /**
     * When media stream state is updated, link it to the video element.
     */
    useEffect(() => {
        // Set up the camera feed for the video element.
        const setUpCamera = async () => {
            const constraints = {
                video: { width: { min: 1280 }, height: { min: 720 } }
            };

            // If the media stream has not been set yet, create one and store it.
            if (mediaStream === null) {
                const createdMediaStream = await getDeviceMedia(constraints);
                setMediaStream(createdMediaStream);
            }
        };

        setUpCamera();
    }, [mediaStream]);

    // Removes the media stream on unmount.
    useEffect(() => () => {
        if (mediaStream === null) {
            return;
        }

        mediaStream.getTracks().forEach((track) => {
            track.stop();
        });

        setMediaStream(null);
    }, [mediaStream]);

    return {
        mediaStream
    };
};

export default useVideoMediaStream;
