/**
 * Calculates the ratio of an image and creates new scaled dimensions.
 * @param currentWidth - The current width of the image.
 * @param currentHeight - The current height of the image.
 * @param maxWidth - The max width to scale to.
 * @param maxHeight - The max height to scale to.
 * @returns {object}
 */

interface returnType {
    scaledHeight: number,
    scaledWidth: number
}

const scaleImage = (currentWidth: number, currentHeight: number, maxWidth: number, maxHeight: number): returnType => {
    const theBiggestDimension = (currentHeight > currentWidth) ? 'height' : 'width';

    const ratio = currentWidth / currentHeight;

    const scaledHeight = theBiggestDimension === 'height' ? maxHeight : maxHeight / ratio;
    const scaledWidth = theBiggestDimension === 'width' ? maxWidth : maxWidth * ratio;

    return {
        scaledHeight,
        scaledWidth
    };
};

export default scaleImage;
