import { RefObject, FormEvent } from 'react';

import validateFileUpload from '../helpers/validateFileUpload';

interface useImageUploadInput {
    handleUploadClick: () => void,
    handleFileUpload: (event: FormEvent<HTMLInputElement>) => void
}

const useImageUploadInput = (inputRef: RefObject<HTMLInputElement>, onImageUpload: (imageSrc: string) => void, onError: () => void) => {
    /**
     * A function call to retrieve the uploaded image from a file input element.
     * @param event - File input event.
     * @returns {void}
     */
    const handleFileUpload = async (event: FormEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;

        // Get the files from the input.
        const { files } = input;

        // Get and validate the uploaded file.
        const image = validateFileUpload(files);
        if (image === false) {
            onError();
            return;
        }

        // Get the image src and send it through the callback.
        const src = URL.createObjectURL(image);

        // Clear the input value after submission.
        input.value = '';

        onImageUpload(src);
    };

    /**
     * Sends a click event to the hidden upload input.
     * @returns {void}
     */
    const handleUploadClick = () => {
        if (!inputRef.current) {
            return;
        }

        // Send the click event.
        inputRef.current.click();
    };

    return {
        handleUploadClick,
        handleFileUpload
    };
};

export default useImageUploadInput;
