/**
 * NOTE: do NOT include "Content-Type: Multipart/form-data". Browser needs to auto generate boundries.
 * https://newbedev.com/how-do-i-post-with-multipart-form-data-using-fetch (reference link).
 */

interface cmfConfigInterface {
    headers: {
        Accept: 'application/json',
       'Accept-Encoding': 'gzip, deflate, br',
        'x-mock-response-name'?: string,
    },
    credentials: 'omit',
    cache: 'no-cache',
    mode: 'cors'
}

const cmfConfig: cmfConfigInterface = {
    headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip, deflate, br',
    },
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit',
};

export default cmfConfig;
