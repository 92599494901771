import React, { ReactElement } from 'react';

import styles from './ProgressBar.module.scss';

interface props {
    steps: number,
    currentStep: number,
    className?: string
}

const ProgressBar = ({ steps, currentStep, className }: props): ReactElement => {
    // Creates an array from the number of steps for the progress bar.
    const numberOfSteps = Array.from({ length: steps }, (v, i) => i);

    return (
        <div className={`${styles.progressBar} ${className}`}>
            {numberOfSteps.map((i) => {
                let bgColour = 'bg-smoke';

                if (i < currentStep) {
                    bgColour = 'bg-dark';
                }

                if (i === currentStep) {
                    bgColour = 'bg-primary';
                }

                return <div key={i} className={`${styles.singleBar} ${bgColour}`} />;
            })}
        </div>
    );
};

ProgressBar.defaultProps = {
    className: ''
};

export default ProgressBar;
