import React, { Fragment, ReactElement, useEffect } from 'react';

import useCompareYourFaceContext from '../context/compareYourFaceContext';

import LifestyleChoiceDetails from '../../../components/Panels/LifestyleChiceDetails/LifestyleChoiceDetails';
import PanelAnimation from '../../../components/Panels/PanelAnimation/PanelAnimation';
import Share from '../../../components/Panels/Share/Share';
import useDesktopNavigationContext from '../../../context/desktopNavigationContext';

const CompareYourFacePanels = (): ReactElement => {
    const { showShare, toggleShare, readMoreSate, clearReadMore, closeReadMore, showGoodFuture, handleBack } = useCompareYourFaceContext();

    const { addNavigationFunctions } = useDesktopNavigationContext();

    useEffect(() => {
        let handleClose = readMoreSate.show ? closeReadMore : null;
        if (handleClose === null && showShare) {
            handleClose = toggleShare;
        }

        addNavigationFunctions(handleBack, handleClose);
    }, [handleBack, readMoreSate, showShare]);

    return (
        <Fragment>
            <PanelAnimation showPanel={showShare}>
                <Share onClose={toggleShare} />
            </PanelAnimation>
            <PanelAnimation showPanel={readMoreSate.show} onExited={clearReadMore}>
                <LifestyleChoiceDetails
                    lifestyleChoice={readMoreSate.data as lifestyleChoiceInterface}
                    showGoodFuture={showGoodFuture}
                    onClose={closeReadMore}
                />
            </PanelAnimation>
        </Fragment>
    );
};

export default CompareYourFacePanels;
