/**
 * NOTE: Variables with the different speeds of animation are matching the styles.scss root css variables.
 * If you change these please change the CSS variables in that file.
 */

export const ANIMATION_FAST = 250;

export const ANIMATION_MEDIUM = 500;

export const ANIMATION_SLOW = 750;
