import React, { ReactElement, Fragment } from 'react';

import lifestylesData from '../../../config/lifestyles.json';

import usePageContext from '../../../context/pageContext';
import useTranslation from '../../../hooks/useTranslation';
import styles from './AgeingOnlyDescription.module.scss';
import useCompareYourFaceContext from '../context/compareYourFaceContext';

import Toggle from '../../../components/_ui/Toggle/Toggle';
import Button from '../../../components/_ui/Button/Button';
import ToggleBubble from '../../../components/_ui/Toggle/ToggleBubble/ToggleBubble';

/**
 * Ageing only description component.
 * @returns {ReactElement}
 */
const AgeingOnlyDescription = (): ReactElement => {
    // We need to check if there's any lifestyles
    const { questions } = lifestylesData;

    // Get the age image toggle functions.
    const { toggleRelativeAge, displayRelativeAgeToggle } = useCompareYourFaceContext();

    // Get the next page function.
    const { onNextPage, jumpToPage } = usePageContext();

    // Gets the translate function.
    const { translate } = useTranslation();

    const handleClick = (questions.length > 0) ? onNextPage : () => jumpToPage('SPLASH');

    return (
        <Fragment>
            <div className={styles.age}>
                <h2 className="text-heading">{translate('compare_your_face.age')}</h2>
                {displayRelativeAgeToggle
                    ? <Toggle onChange={toggleRelativeAge} optionOne="+10" optionTwo="+20" />
                    : <ToggleBubble isOn>+10</ToggleBubble>}
            </div>
            <div className={styles.score}>
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: translate('compare_your_face.ageing_only') }} />
                <Button onClick={handleClick} className="w-full mt-8">
                    {translate('compare_your_face.buttons.next_page')}
                </Button>
            </div>
        </Fragment>
    );
};

export default AgeingOnlyDescription;
