import { i18n as i18nType } from 'i18next';
import { useTranslation as useTransation_i18nReact } from 'react-i18next';

interface useTranslationInterface {
    translate: (path: string, defaultString?: string) => string
    randomTranslation: (path: string) => string
    changeLanguage: (language: string) => void,
    exists: (key: string) => boolean
    i18n: i18nType
}

const useTranslation = (): useTranslationInterface => {
    const { t, i18n } = useTransation_i18nReact();
    return {
        translate: t,
        exists: (key: string) => i18n.exists(key) && t(key) !== '' && t(key) !== 'IGNORE',
        // eslint-disable-next-line
        // @ts-ignore
        randomTranslation: i18n.TRandom.bind(i18n),
        changeLanguage: i18n.changeLanguage.bind(i18n),
        i18n
    };
};

export default useTranslation;
