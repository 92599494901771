import React, { ReactElement, useEffect, useState } from 'react';

import useGlobalContext, { ENABLE_AGEING_ONLY } from '../../context/globalContext';
import usePageContext from '../../context/pageContext';
import useTranslation from '../../hooks/useTranslation';
import styles from './LifestyleInstructions.module.scss';
import pageStyles from '../scss/Pages.module.scss';

import Button from '../../components/_ui/Button/Button';
import PreloadLifeStyleImages from './PreloadLifeStyleImages';
import PanelAnimation from '../../components/Panels/PanelAnimation/PanelAnimation';
import lifestylesData from '../../config/lifestyles.json';
import Lifestyles from '../../components/Panels/Lifestyles/Lifestyles';
import useDesktopNavigationContext from '../../context/desktopNavigationContext';

const LifestyleInstructions = (): ReactElement => {
    // Get the questions from the lifestyles config.
    const { questions } = lifestylesData;

    const [showLifestyles, setShowLifestylesPanel] = useState(false);
    const toggleShowLifestyles = () => setShowLifestylesPanel((state) => !state);

    // Get global user data and the users age only preference.
    const { userData, setAgeingOnly, setLifestyleAnswers } = useGlobalContext();

    const { addNavigationFunctions } = useDesktopNavigationContext();

    // Get page navigation functions.
    const { onNextPage, jumpToPage } = usePageContext();

    // Translate the text for the page buttons.
    const { translate } = useTranslation();

    /**
     * Calculate the default lifestyle values and send the aging request to the API.
     */
    const handleAgingClick = () => {
        setAgeingOnly(true);
        setLifestyleAnswers({});
        onNextPage();
    };

    /**
     * Sends the user back to the previous page.
     * @returns {void}
     */
    const handleBack = () => jumpToPage('PHOTO_INSTRUCTIONS');

    // If the user navigates back to this page, reset the ageing only option.
    useEffect(() => {
        setAgeingOnly(false);
        setLifestyleAnswers({});
    }, []);

    useEffect(() => {
        if (showLifestyles) {
            addNavigationFunctions(handleBack, toggleShowLifestyles);
            return;
        }

        addNavigationFunctions(handleBack, null);
    }, [showLifestyles]);

    return (
        <main className={`${pageStyles.page} ${styles.wrapper}`}>
            <PreloadLifeStyleImages />
            <div className={`${styles.inner} container-xlarge`}>
                <div className={styles.photo} style={{ backgroundImage: `url(${userData.uploadedPhoto})` }}>
                    <div className={styles.gradient}>
                        <button className={styles.editButton} onClick={handleBack} type="button">
                            {translate('lifestyle_instructions.buttons.edit')}
                        </button>
                    </div>
                </div>
                <section className={styles.card}>
                    <h1 className="text-heading">{translate('lifestyle_instructions.title')}</h1>
                    <p className="text-subheading mt-4">{translate('lifestyle_instructions.body_text')}</p>
                    <div className="flex gap-3 mt-8">
                        {
                            (ENABLE_AGEING_ONLY)
                                ? (
                                    <Button onClick={handleAgingClick} primary={false} className={`${styles.button} grow`}>
                                        {translate('lifestyle_instructions.buttons.ageing')}
                                    </Button>
                                )
                                : null
                        }
                        {
                            (questions.length > 1)
                                ? (
                                    <Button onClick={toggleShowLifestyles} className={`${styles.button} grow`}>
                                        {translate('lifestyle_instructions.buttons.lifestyle')}
                                    </Button>
                                )
                                : null
                        }
                    </div>
                </section>
            </div>
            <PanelAnimation showPanel={showLifestyles}>
                <Lifestyles onClose={toggleShowLifestyles} />
            </PanelAnimation>
        </main>
    );
};

export default LifestyleInstructions;
