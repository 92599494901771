import React, { ReactElement, Fragment } from 'react';

import styles from './LifestyleDescription.module.scss';
import useTranslation from '../../../hooks/useTranslation';
import useCompareYourFaceContext from '../context/compareYourFaceContext';
import useGlobalContext from '../../../context/globalContext';
import usePageContext from '../../../context/pageContext';

import Toggle from '../../../components/_ui/Toggle/Toggle';
import Button from '../../../components/_ui/Button/Button';
import GoodFuture from '../../../components/LifestyleScores/GoodFuture/GoodFuture';
import BadFuture from '../../../components/LifestyleScores/BadFuture';
import ToggleBubble from '../../../components/_ui/Toggle/ToggleBubble/ToggleBubble';

/**
 * The description of the users lifestyle scores.
 * @returns {ReactElement}
 */
const LifestyleDescription = (): ReactElement => {
    // Gets the lifestyle scores.
    const { lifestyleScores, goodFutureImages } = useGlobalContext();

    // Get the next page function.
    const { onNextPage } = usePageContext();

    // Get compare your face context values.
    const { showGoodFuture, openReadMore, toggleRelativeAge, setShowDelayOverlay, handleShowGoodFuture, displayRelativeAgeToggle } = useCompareYourFaceContext();

    // Gets the translation function.
    const { translate } = useTranslation();

    // If the user has given the max possible score.
    const hasMaxScore = lifestyleScores.currentScore === lifestyleScores.potentialScore;

    const clickHandler = () => {
        if (goodFutureImages.length < 1) {
            setShowDelayOverlay(true);
            return false;
        }
        return (hasMaxScore || showGoodFuture) ? onNextPage() : handleShowGoodFuture();
    };

    return (
        <Fragment>
            <div className={styles.age}>
                <h2 className="text-heading">{translate('compare_your_face.age')}</h2>
                {displayRelativeAgeToggle
                    ? <Toggle onChange={toggleRelativeAge} optionOne="+10" optionTwo="+20" />
                    : <ToggleBubble isOn>+10</ToggleBubble>}
            </div>
            <div className={styles.score}>
                {showGoodFuture
                    ? <GoodFuture onReadMore={openReadMore} />
                    : <BadFuture onReadMore={openReadMore} />}
                <Button
                    onClick={clickHandler}
                    className="w-full mt-8"
                >
                    {(hasMaxScore || showGoodFuture)
                        ? translate('compare_your_face.buttons.next_page')
                        : translate('compare_your_face.buttons.good_future')}
                </Button>
            </div>
        </Fragment>
    );
};

export default LifestyleDescription;
