import React, { Fragment, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';

import translations from '../../config/translations/translations.json';
import styles from './TranslationSwitcher.module.scss';
import { LANGUAGE_LOCAL_STORAGE_KEY } from '../../constants/languageLocalStorageKey';

import Chip from '../_ui/Chip/Chip';
import Dialog from '../_ui/Dialog/Dialog';
import Radio from '../_ui/Forms/Inputs/Radio/Radio';

const TranslationSwitcher = () => {
    const { translate, changeLanguage, i18n } = useTranslation();
    const [languageDialogIsOpen, setLanguageDialogIsOpen] = useState(false);

    // handle change langage needs to store the language in the local storage
    const handleLanguageChange = (language: string) => {
        changeLanguage(language);
        localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language);

        setTimeout(() => {
            setLanguageDialogIsOpen(false);
        }, 100);
    };

    if (translations.length < 2) return null;

    const formatLabel = (str:string) => {
        let formattedLabel = null;

        if (str.indexOf('_') > -1) {
            const S = str.split('_');
            formattedLabel = `${S[0]} (${S[1]})`;
        } else {
            formattedLabel = str;
        }

        return formattedLabel;
    };

    return (
        <Fragment>
            <Chip
                label={formatLabel(i18n.language)}
                badge={`/icons/flags/${i18n.language}.svg`}
                icon="CHEVRON_DOWN"
                onClick={() => { setLanguageDialogIsOpen(true); }}
                className={styles.translationSwitcher}
            />

            <Dialog
                title="Select language"
                isOpen={languageDialogIsOpen}
                onClose={() => { setLanguageDialogIsOpen(false); }}
            >
                {translations.map((lang) => {
                    return (
                        <Radio
                            name="language"
                            id={lang}
                            key={lang}
                            value={lang}
                            onChange={(e) => handleLanguageChange(e.target.value)}
                            onBlur={(e) => handleLanguageChange(e.target.value)}
                            className={styles.TranslationDialogOption}
                            checked={lang === i18n.language}
                        >
                            <img src={`/icons/flags/${lang}.svg`} alt="lang" className={styles.TranslationDialogOptionFlag} />
                            <span>{translate(`translations.${lang}`, lang)}</span>
                        </Radio>
                    );
                })}
            </Dialog>
        </Fragment>
    );
};

export default TranslationSwitcher;
