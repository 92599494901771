import React, { ReactElement } from 'react';

import useTranslation from '../../hooks/useTranslation';
import usePageContext from '../../context/pageContext';

import useManageAgeInput from './hooks/useManageAgeInput';
import pageStyles from '../scss/Pages.module.scss';
import useGlobalContext from '../../context/globalContext';
import styles from './Start.module.scss';

import Button from '../../components/_ui/Button/Button';
import FieldLabel from '../../components/_ui/Forms/FieldLabel/FieldLabel';
import NumberInput from '../../components/_ui/Forms/Inputs/NumberInput/NumberInput';

/**
 * The start page for the app.
 * @returns {ReactElement}
 */
const Start = (): ReactElement => {
    const { setUserData } = useGlobalContext();

    // Get the translate function.
    const { translate } = useTranslation();

    // Get next page navigation function.
    const { onNextPage } = usePageContext();

    // Get the age value, input function and validation items.
    const { age, isAgeValid, errorMessage, handleUpdateAge } = useManageAgeInput();

    // Saves the users age in global state and moves the user to the next page.
    const handleNextPage = () => {
        const usersAge = age as number;
        setUserData((state) => ({ ...state, age: usersAge }));
        onNextPage();
    };

    return (
        <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex overflow-auto`}>
            <div className="container-small my-auto w-full">
                <img
                    className={styles.image}
                    src="/images/face-with-mask.svg"
                    alt={translate('global.images.face_with_computer_scan_lines')}
                />
                <section className="mt-6">
                    <h1 className="text-center text-heading">{translate('start.title')}</h1>
                    <p className={`text-subheading ${styles.description}`}>{translate('start.body_text')}</p>
                    <FieldLabel
                        id="age"
                        label={translate('start.fields.age.label')}
                        className="w-full mt-6"
                        isValid={isAgeValid}
                        errorMessage={errorMessage}
                    >
                        <NumberInput
                            value={age === null ? '' : age}
                            id="age"
                            pattern="[0-9]*"
                            onChange={handleUpdateAge}
                            isValid={isAgeValid}
                            required
                            className={styles.ageInput}
                        />
                    </FieldLabel>
                    <Button className="w-full mt-6" onClick={handleNextPage} disabled={isAgeValid === false}>
                        {translate('start.buttons.next_page')}
                    </Button>
                </section>
            </div>
        </main>
    );
};

export default Start;
