/**
 * Gets the base64 string of the provided image.
 * @param image - HTMLImageElement.
 * @param imageWidth - The width of the image.
 * @param imageHeight - The height of the image.
 * @returns {string} - The base64 string.
 */
const getImageBase64 = (image: HTMLImageElement | HTMLVideoElement, imageWidth: number, imageHeight: number): string => {
    // Create a canvas.
    let canvas: HTMLCanvasElement | null = document.createElement('canvas');

    // Set the canvas width and height.
    canvas.width = imageWidth;
    canvas.height = imageHeight;

    // Convert the canvas to a 2d context.
    const context = canvas.getContext('2d');

    // Add the image to the canvas.
    context?.drawImage(image, 0, 0, imageWidth, imageHeight);

    // Get the image as a base64 string.
    const base64 = canvas.toDataURL('image/jpeg');

    // clear the old canvas.
    canvas = null;

    // return the base64.
    return base64;
};

export default getImageBase64;
