/* eslint-disable react/button-has-type */
import React, { ReactNode, ReactElement } from 'react';

import styles from './Button.module.scss';

interface props {
    type?: 'button' | 'submit',
    onClick?: () => void,
    primary?: boolean,
    className?: string,
    children: ReactNode,
    disabled?: boolean
}

const Button = ({ type, onClick, primary, className, children, disabled }: props): ReactElement => {
    const primaryClass = primary ? styles.buttonPrimary : styles.buttonSecondary;

    return (
        <button
            type={type}
            onClick={onClick}
            className={`${styles.button} ${primaryClass} ${className}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    onClick: undefined,
    primary: true,
    className: '',
    disabled: false
};

export default Button;
