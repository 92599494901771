import { pageKeysType, pageDirectionType } from '../../context/pageContext';

import styles from '../App.module.scss';

interface exitInterface {
    exit: string,
    exitActive: string,
    exitDone: string
}

interface enterAndExitInterface {
    enter: string,
    enterActive: string,
    enterDone: string,
    exit: string,
    exitActive: string,
    exitDone: string
}

type getPageAnimationClassesType = exitInterface | enterAndExitInterface;

/**
 * Returns the animation classes for a CSSTransition component for page animation.
 * @param currentPage - The current page.
 * @param direction - The direction of page travel (forward/backward).
 * @returns {getPageAnimationClassesType}
 */
const getPageAnimationClasses = (currentPage: pageKeysType, direction: pageDirectionType): getPageAnimationClassesType => {
    if (currentPage === 'SPLASH') {
        return {
            exit: styles.a_slideUp,
            exitActive: styles.a_slideUpActive,
            exitDone: styles.a_slideUpDone,
        };
    }

    if (currentPage === 'START' && direction === 'FORWARD') {
        return {
            exit: styles.a_slideOutLeft,
            exitActive: styles.a_slideOutLeftActive,
            exitDone: styles.a_slideOutLeftDone,
        };
    }

    if (direction === 'FORWARD') {
        return {
            enter: styles.a_slideInRight,
            enterActive: styles.a_slideInRightActive,
            exit: styles.a_slideOutLeft,
            exitActive: styles.a_slideOutLeftActive,
            exitDone: styles.a_slideOutLeftDone,
        };
    }

    return {
        enter: styles.a_slideInLeft,
        enterActive: styles.a_slideInLeftActive,
        exit: styles.a_slideOutRight,
        exitActive: styles.a_slideOutRightActive,
        exitDone: styles.a_slideOutRightDone,
    };
};

export default getPageAnimationClasses;
