import React, { ReactElement } from 'react';

import Check from './Icons/Check';
import ChevronLeft from './Icons/ChevronLeft';
import ChevronDown from './Icons/ChevronDown';
import Cross from './Icons/Cross';
import WarningOutline from './Icons/WarningOutline';

export const icons = {
    CHECK: Check,
    CROSS: Cross,
    CHEVRON_LEFT: ChevronLeft,
    CHEVRON_DOWN: ChevronDown,
    WARNING_OUTLINE: WarningOutline,
};

interface props {
    icon: keyof typeof icons,
    width?: number | string,
    height?: number | string,
    className?: string
}

const Icon = ({ icon, width, height, className }: props): ReactElement => {
    const IconToRender = icons[icon];

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 60 60"
            className={`fill-current ${className}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <IconToRender />
        </svg>
    );
};

Icon.defaultProps = {
    width: 60,
    height: 60,
    className: ''
};

export default Icon;
